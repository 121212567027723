import React, { useState, useEffect } from "react";
import { MdLock } from "react-icons/md";
import { ClientInfo } from "@blisspointmedia/bpm-types/dist/Onboarding";
import useLocation from "../utils/hooks/useLocation";
import { Spinner } from "../Components/Spinner";
import { Page } from "../Components";
import CircularProgressBar from "../Components/CircularProgressBar/CircularProgressBar";
import { useTabbedNav } from "../utils/hooks/useNav";
import { RouteComponentProps } from "@reach/router";
import BudgetsBilling from "./BudgetsBilling/BudgetsBilling";
import CoreReporting from "./CoreReporting/CoreReporting";
import Data from "./Data/Data";
import PlatformAccess from "./PlatformAccess/PlatformAccess";
import PlatformBudgets from "./PlatformBudgets/PlatformBudgets";
import PlatformChecklist from "./PlatformChecklist/PlatformChecklist";
import { getClientInfo } from "./OnboardingUtils";
import "./Onboarding.scss";

const enum TabKey {
  INTRO = "intro",
  PLATFORM_CHECKLIST = "platform-checklist",
  PLATFORM_ACCESS = "platform-access",
  CORE_REPORTING = "core-reporting",
  BUDGETS_BILLING = "budgets-billing",
  PLATFORM_BUDGETS = "platform-budgets",
  DATA = "data",
}

const Onboarding: React.FC = ({ navigate }: RouteComponentProps) => {
  const { company } = useLocation();
  const [clientInfo, setClientInfo] = useState<ClientInfo | null>(null);
  const [dataPercentage, setDataPercentage] = useState<number>(0);

  const NAVS = [
    { label: "Intro", key: TabKey.INTRO, percentage: 100 },
    { label: "1. Platform Checklist", key: TabKey.PLATFORM_CHECKLIST, percentage: 40 },
    { label: "2. Platform Access", key: TabKey.PLATFORM_ACCESS, percentage: 0, icon: <MdLock size={30} /> },
    { label: "3. Core Reporting", key: TabKey.CORE_REPORTING, percentage: 80 },
    { label: "4. Budgets/Billing", key: TabKey.BUDGETS_BILLING, percentage: 90 },
    { label: "5. Platform Budgets", key: TabKey.PLATFORM_BUDGETS, percentage: 100 },
    { label: "6. Data", key: TabKey.DATA, percentage: dataPercentage },
  ];

  useEffect(() => {
    const fetchClientInfo = async () => {
      const info = await getClientInfo(company);
      setClientInfo(info);
    };

    fetchClientInfo();
  }, [company]);

  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "client-info-page-dev",
    defaultKey: TabKey.INTRO,
  });

  if (!clientInfo) {
    return <Spinner size={2} />;
  }

  const handleDataPercentageChange = (percentage: number) => {
    setDataPercentage(percentage);
  };

  return (
    <Page
      title="Onboarding"
      pageType="Onboarding"
      app2Redesign
      navs={NAVS}
      onNav={goToTab}
      selectedNav={tab}
      navRenderer={(label) => {
        const navItem = NAVS.find((nav) => nav.label === label);
        if (!navItem) {
          return (<span>{label}</span>);
        }

        return (
          <span key={navItem.key} className="onboardingProgress">
            <CircularProgressBar
              className="onboardingProgressBar"
              percentage={navItem.percentage}
              size={60}
              icon={navItem.icon}
            />
            {label}
          </span>
        );
      }}
    >
      <div>
        {tab === TabKey.INTRO && <div><h1>Intro</h1></div>}
        {tab === TabKey.PLATFORM_CHECKLIST && <PlatformChecklist />}
        {tab === TabKey.PLATFORM_ACCESS && <PlatformAccess />}
        {tab === TabKey.CORE_REPORTING && <CoreReporting />}
        {tab === TabKey.BUDGETS_BILLING && <BudgetsBilling />}
        {tab === TabKey.PLATFORM_BUDGETS && <PlatformBudgets />}
        {tab === TabKey.DATA && (
          <Data clientInfo={clientInfo} onPercentageChange={handleDataPercentageChange} />
        )}
      </div>
    </Page>
  );
};

export default Onboarding;
