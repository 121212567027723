import React from "react";
import OnboardingFormPage from "../OnboardingFormPage";

enum CoreReportingTabs {
  CONTACT_INFO = "contact-info",
  BUDGETS = "budgets",
  CALENDAR = "calendar",
  METRICS = "metrics",
  NAMING_CONVENTIONS = "naming-conventions",
}

const renderTabs = [
  { label: "Contact Info", headerLabel: "A. Contact Info", key: CoreReportingTabs.CONTACT_INFO, percentage: 100, children: <div>TODO: Contact Info</div> },
  { label: "Budgets", headerLabel: "B. Budgets", key: CoreReportingTabs.BUDGETS, percentage: 0, children: <div>TODO: Budgets</div> },
  { label: "Calendar", headerLabel: "C. Calendar", key: CoreReportingTabs.CALENDAR, percentage: 0, children: <div>TODO: Calendar</div> },
  { label: "Metrics", headerLabel: "D. Metrics", key: CoreReportingTabs.METRICS, percentage: 0, children: <div>TODO: Metrics</div> },
  { label: "Naming Conventions", headerLabel: "E. Naming Conventions", key: CoreReportingTabs.NAMING_CONVENTIONS, percentage: 0, children: <div>TODO: Naming Conventions</div> },
];


const CoreReporting: React.FC = () => {
  return (
    <OnboardingFormPage
      renderTabs={renderTabs}
      defaultActiveTab={CoreReportingTabs.CONTACT_INFO}
      tabs={[CoreReportingTabs.CONTACT_INFO, CoreReportingTabs.BUDGETS, CoreReportingTabs.CALENDAR, CoreReportingTabs.METRICS, CoreReportingTabs.NAMING_CONVENTIONS]}
    />
  );
};

export default CoreReporting;
