import React, { useState } from "react";
import { Nav, Spinner } from "react-bootstrap";
import { MdSave } from "react-icons/md";
import { Card, Button, ButtonType } from "../Components";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import CircularProgressBar from "../Components/CircularProgressBar/CircularProgressBar";

interface OnboardingFormPageProps {
  renderTabs: {
    label: string;
    headerLabel: string;
    key: string;
    percentage: number;
    children: React.ReactNode;
  }[];
  defaultActiveTab: string;
  tabs: string[];
  saveSubmitting?: boolean;
  onSave?: () => void;
}

const OnboardingFormPage = ({
  renderTabs,
  defaultActiveTab,
  tabs,
  saveSubmitting = false,
  onSave,
}: OnboardingFormPageProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState<typeof tabs>(defaultActiveTab as unknown as typeof tabs);
  const [loading, setLoading] = useState(false);

  const buttonText = loading ? "Saving..." : "Save";
  const icon = loading ? <Spinner animation="border" size="sm" /> : <MdSave />;

  const handleSave = async () => {
    if (onSave) {
      setLoading(true);
      await onSave();
      setLoading(false);
    }
  };

  return (
    <Card className="onboardingPage formPage">
      <div className="formPageNavBar">
        <Nav
          activeKey={activeTab}
          onSelect={value => {
            const valueTab = value as keyof typeof value;

            if (!valueTab || valueTab === activeTab) {
              return;
            }
            setActiveTab(valueTab);
          }}
        >
          {renderTabs.map(({ headerLabel, key, percentage }) => (
            <Nav.Item key={key}>
              <Nav.Link href={`#${key}`} eventKey={key}>
                <span key={key} className="onboardingProgress">
                  <CircularProgressBar
                    className="onboardingProgressBar"
                    percentage={percentage}
                    size={55}
                  />
                  {headerLabel}
                </span>
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        <div className="formPageSaveButton">
          <Button
            type={ButtonType.FILLED}
            variant={ButtonFrameworkVariant.LEADING_ICON}
            icon={icon}
            onClick={handleSave}
            disabled={!saveSubmitting}
            title="Save"
          >
            {buttonText}
          </Button>
        </div>
      </div>
      <hr />
      {renderTabs.map(({ label, key, children }) => (
        <div key={key} id={key}>
          <h2 className="formPageHeader">{label}</h2>
          {children}
          <hr />
        </div>
      ))}
    </Card>
  );
};

export default OnboardingFormPage;
