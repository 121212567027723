import "./GenerateView.scss";
import { Button, ListGroup, Modal } from "react-bootstrap";
import { DeviceNames, OSNames } from "../utils";
import { CheckBox, IconButton, OldFilterBar } from "../../Components";
import { MdDone, MdCheckBoxOutlineBlank } from "react-icons/md";
import { TagStagingArea } from "./TagStagingArea";
import { TODAY } from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import { useAudioCompanionOptions } from "../../StreamingCreatives/StreamingCreatives";
import { useCreativeMap } from "../../redux/creative";
import { useSetError, useSetAreYouSure } from "../../redux/modals";
import { useStateFunction } from "../../utils/hooks/useData";
import * as R from "ramda";
import React, { useMemo, useState, useCallback, useEffect } from "react";
import TagCreationModal from "./TagCreationModal";
import TagRow from "./TagRow";
import useLocation from "../../utils/hooks/useLocation";
import {
  awaitJSON,
  CreativeLambdaFetch,
  ExtremeReachLambdaFetch,
  FlashtalkingLambdaFetch,
  StreamingV2LambdaFetch,
} from "../../utils/fetch-utils";

export const TagCreationContext = React.createContext();

const GENERATE_FILTER_BAR_OPTIONS = [
  { name: "derivedID", label: "Derived Network" },
  { name: "network", label: "Network" },
  { name: "description", label: "Description" },
  { name: "platform", label: "Platform" },
  { name: "dsp", label: "DSP" },
  { name: "placementName", label: "Placement Name" },
];

const SendingState = {
  SENDING: 0,
  REFRESHING_DATA: 1,
  CLEARING_STAGED_TAGS: 2,
  COMPLETE: 3,
};

const GenerateView = ({
  tagInfo,
  suppressedMap,
  setSuppressedMap,
  shouldRefreshData,
  setShouldRefreshData,
}) => {
  const { company } = useLocation();
  const setError = useSetError();
  const setAreYouSure = useSetAreYouSure(true);
  const [filter, setFilter] = useStateFunction(() => true);
  const [onlyMissing, setOnlyMissing] = useState(true);
  const [flashtalkingCampaigns, setFlashtalkingCampaigns] = useState();
  const [flashtalkingSites, setFlashtalkingSites] = useState();
  const [showSendTagsToNetworksModal, setShowSendTagsToNetworksModal] = useState(false);
  const [selectedTagsForNetworkSendMap, setSelectedTagsForNetworkSendMap] = useState({});
  const { creativeMap } = useCreativeMap({
    company: company,
    mediaTypes: ["audio", "display", "streaming"],
  });

  const filteredTagInfo = useMemo(() => {
    if (tagInfo && flashtalkingCampaigns) {
      return R.filter(tag => {
        if (tag.adServer !== "ft") {
          return true;
        } else {
          const campaign = R.find(R.propEq("campaign_id", tag.campaignID), flashtalkingCampaigns);
          if (!campaign || campaign.is_managed_service) {
            return false;
          }
          return true;
        }
      }, tagInfo);
    }
    return tagInfo;
  }, [flashtalkingCampaigns, tagInfo]);

  const cachedTags = useMemo(() => {
    let cache = JSON.parse(localStorage.getItem(`${company}_staged_tags`));

    if (!cache) {
      return [];
    }

    let hoursElapsed = (new Date().getTime() - new Date(cache.date).getTime()) / (60 * 60 * 1000);

    // Only used cached tags from the past work day (~10 hours ago) so that people don't
    // accidentally send old cached tags.
    if (hoursElapsed > 10) {
      localStorage.removeItem(`${company}_staged_tags`);
      return [];
    }

    return cache.value;
  }, [company]);

  const [stagedTags, setStagedTags] = useState(cachedTags || []);
  const [isSendingTagsToAdServer, setIsSendingTagsToAdServer] = useState(SendingState.COMPLETE);

  const [filteredLines, missingCount] = useMemo(() => {
    const alreadyAdded = R.pipe(R.groupBy(R.prop("derivedID")))(stagedTags);
    let derivedMap = {};
    let missingCount = 0;
    for (let line of filteredTagInfo) {
      if (flashtalkingSites) {
        for (const site of flashtalkingSites) {
          if (
            site &&
            site.streaming_networks_short_codes &&
            site.streaming_networks_short_codes.includes(line.network)
          ) {
            line.flashtalking_vendors = R.uniq([
              ...R.defaultTo([], line.flashtalking_vendors),
              site.name,
            ]);
          }
        }
      }
      if (line.flashtalking_vendors) {
        line.flashtalking_vendors = R.uniq(line.flashtalking_vendors);
      }
      if (
        line.hasPlacement ||
        line.hasSubmittedPendingPlacement ||
        line.hasOverride ||
        suppressedMap[line.derivedID]
      ) {
        if (!onlyMissing && filter(line)) {
          let lines = derivedMap[line.derivedID] || [];
          lines.push(line);
          derivedMap[line.derivedID] = lines;
        }
      } else if (!(onlyMissing && alreadyAdded[line.derivedID])) {
        missingCount++;
        if (filter(line)) {
          let lines = derivedMap[line.derivedID] || [];
          lines.push(line);
          derivedMap[line.derivedID] = lines;
        }
      }
    }
    return [R.values(derivedMap), missingCount];
  }, [stagedTags, filteredTagInfo, suppressedMap, onlyMissing, flashtalkingSites, filter]);

  const [formChanges, setFormChanges] = useState({});
  const [formFail, setFormFail] = useState(false);

  useEffect(() => {
    (async () => {
      if (R.isNil(flashtalkingCampaigns)) {
        const campaignResp = await FlashtalkingLambdaFetch("/getFlashtalkingCampaigns", {
          params: { company },
        });
        const campaigns = await awaitJSON(campaignResp);
        setFlashtalkingCampaigns(campaigns);
      }
    })();
  }, [company, flashtalkingCampaigns]);

  useEffect(() => {
    (async () => {
      if (R.isNil(flashtalkingSites)) {
        const sitesResp = await FlashtalkingLambdaFetch("/getFlashtalkingSites", {
          params: {},
        });
        const sites = await awaitJSON(sitesResp);
        setFlashtalkingSites(sites);
      }
    })();
  }, [company, flashtalkingSites]);

  const suppressTag = useCallback(
    async derivedID => {
      try {
        await setAreYouSure({
          title: "Suppressing Tag",
          variant: "danger",
          message: `You are about to mark "${derivedID}" as tag-less. You should only do this if it corresponds to a flight you know will never run. Are you sure absolutely certain this should not have a tag?`,
          okayText: "I'm Certain",
        });
      } catch (e) {
        return;
      }
      setSuppressedMap(map => ({ ...map, [derivedID]: true }));
      try {
        await StreamingV2LambdaFetch("/tag_override", {
          method: "POST",
          body: { derivedID },
        });
      } catch (e) {
        setError({
          message: e.message,
          reportError: e,
        });
        setSuppressedMap(R.omit([derivedID]));
      }
    },
    [setError, setAreYouSure, setSuppressedMap]
  );

  const [tagCreationModalData, setTagCreationModalData] = useState();
  const [isTagCreationModalEditMode, setIsTagCreationModalEditMode] = useState(false);
  const [showTagCreationModal, setShowTagCreationModal] = useState(false);

  const openTagCreationModal = useCallback((tag, isEditMode) => {
    setTagCreationModalData(tag);
    setIsTagCreationModalEditMode(isEditMode);
    setShowTagCreationModal(true);
  }, []);

  const closeTagCreationModal = useCallback(() => {
    setShowTagCreationModal(false);
    setIsTagCreationModalEditMode(false);
  }, []);

  const stageTags = useCallback(tags => {
    setStagedTags(current => [...current, ...tags]);
    setShowTagCreationModal(false);
  }, []);

  const sendTagsToER = useCallback(async () => {
    setIsSendingTagsToAdServer(SendingState.SENDING);
    try {
      let placements = [];
      for (let stagedTag of stagedTags) {
        if (stagedTag.adServer === "Extreme Reach") {
          // Add each staged tag to pending_placements and get back it's new row ID.
          let res = await CreativeLambdaFetch("/addToPending", {
            method: "POST",
            body: {
              placementName: stagedTag.name,
              derivedID: stagedTag.derivedID,
              vendor: stagedTag.vendor,
              clickthroughUrl: stagedTag.clickthroughUrl, // Used if there is a single placement-level clickthrough URL.
            },
          });
          let placementID = await awaitJSON(res);

          let creatives = [];
          for (let isci of Object.keys(stagedTag.creativeAllocationMap)) {
            creatives.push({
              isci,
              pct: stagedTag.creativeAllocationMap[isci],
            });
          }

          const placement = {
            placementID,
            creatives,
            clickthroughUrlMap: stagedTag.clickthroughUrlMap || {},
            audioCompanionMap: stagedTag.audioCompanionMap || {},
            extraTrackingPixels: stagedTag.extraTrackingPixels || [],
            isPending: true,
          };

          placements.push(placement);
        }
      }
      if (placements.length) {
        // Use pending_placements row ID to push the creative allocations
        // into buying_streaming_creatives.
        await CreativeLambdaFetch("/commitCreatives", {
          method: "POST",
          body: { company, placements },
        });

        const placementIds = placements.map(placement => placement.placementID);

        // Send all new placements for the given company to Extreme Reach via their API.
        await ExtremeReachLambdaFetch("/sendPendingPlacementsToER", {
          method: "POST",
          body: {
            company,
            placementIds,
            extraTrackingPixels: [], // TODO: This will be removed once I create a table for these to live in.
          },
        });

        setIsSendingTagsToAdServer(SendingState.REFRESHING_DATA);

        setError({
          title: "Success",
          variant: "success",
          message: "Placements uploaded!",
        });
      }
    } catch (e) {
      setError({
        message: e.message,
        reportError: e,
      });
      setIsSendingTagsToAdServer(SendingState.COMPLETE);
    }
  }, [company, setError, stagedTags]);

  const sendTagsToFlashTalking = useCallback(async () => {
    const siteIDMap = {};
    if (flashtalkingSites) {
      for (const site of flashtalkingSites) {
        siteIDMap[site.name] = site.id;
      }
    }
    setIsSendingTagsToAdServer(SendingState.SENDING);
    try {
      const commitCreativesPlacements = [];
      const creativeErrors = [];
      const placementMap = {};
      const transcodeSiteMap = {};
      let placementIndex = 0;
      for (const stagedTag of stagedTags) {
        if (stagedTag.adServer === "Flashtalking") {
          placementMap[placementIndex] = {
            campaignID: stagedTag.campaignID,
            id: placementIndex,
            name: stagedTag.name.replace(/</g, "Under").replace(/>/g, "Over").replace(/,/g, "/"),
            dimensions: stagedTag.dimensions,
            startDate: R.defaultTo(TODAY, stagedTag.startDate),
            endDate: stagedTag.endDate,
            clickTag1: stagedTag.clickthroughUrl,
            placementType:
              stagedTag.placementType &&
              ["Audio", "Multi-tracker", "Pixel", "VAST"].includes(stagedTag.placementType)
                ? stagedTag.placementType
                : stagedTag.platform.includes("Audio")
                ? "Audio"
                : "VAST",
            costTypeMedia: "CPM",
            costRateMedia:
              stagedTag && stagedTag.platform && stagedTag.platform.includes("Audio")
                ? 0.053
                : 0.135,
            site: {
              name: stagedTag.vendor,
            },
            placementCreatives: R.map(
              isci => ({
                id: ["Multi-tracker", "Pixel"].includes(stagedTag.placementType)
                  ? creativeMap[isci].flashtalking_pixel_id
                  : creativeMap[isci].flashtalking_id,
                name: isci,
                rotationWeight: stagedTag.creativeAllocationMap[isci],
                startDate: TODAY,
                clickTag1: stagedTag.clickthroughUrlMap ? stagedTag.clickthroughUrlMap[isci] : null,
                audioCompanion:
                  stagedTag.audioCompanionMap &&
                  stagedTag.audioCompanionMap[isci] &&
                  stagedTag.audioCompanionMap[isci].adid
                    ? typeof stagedTag.audioCompanionMap[isci].adid === "number"
                      ? stagedTag.audioCompanionMap[isci].adid
                      : parseInt(stagedTag.audioCompanionMap[isci].adid)
                    : null,
              }),
              R.keys(stagedTag.creativeAllocationMap)
            ),
          };
          for (let i = 1; i <= 10; i++) {
            if (stagedTag.extraTrackingPixels && stagedTag.extraTrackingPixels[i]) {
              placementMap[placementIndex][`thirdPartyImpression${i}`] =
                stagedTag.extraTrackingPixels[i];
            }
          }
          if (siteIDMap[stagedTag.transcodeVendor] && stagedTag.transcodeVendor) {
            transcodeSiteMap[placementIndex] = siteIDMap[stagedTag.transcodeVendor];
          }
          placementIndex--;
        }
      }
      if (!R.isEmpty(placementMap)) {
        // Create New Placements in Flashtalking
        // NOTE: Flashtalking immediately creates placements so we do not need
        // pending placement ID's like we do for ER.
        const flashtalkingResponse = await FlashtalkingLambdaFetch(
          "/sendPlacementsToFlashtalking",
          {
            method: "POST",
            body: {
              company,
              placementMap,
              transcodeSiteMap,
            },
          }
        );

        const returnedPlacementMap = await awaitJSON(flashtalkingResponse);

        if (returnedPlacementMap && !R.isEmpty(returnedPlacementMap)) {
          // Add to buying_streaming_creatives
          for (const id of R.keys(returnedPlacementMap)) {
            const creatives = [];
            if (returnedPlacementMap[id].error) {
              continue;
            }
            for (const stagedTag of stagedTags) {
              if (
                stagedTag.adServer === "Flashtalking" &&
                returnedPlacementMap[id].name === stagedTag.name
              ) {
                for (let isci of Object.keys(stagedTag.creativeAllocationMap)) {
                  creatives.push({
                    isci,
                    pct: stagedTag.creativeAllocationMap[isci],
                  });
                }
                const placement = {
                  placementID: id,
                  creatives,
                  clickthroughUrlMap: stagedTag.clickthroughUrlMap || {},
                  audioCompanionMap: stagedTag.audioCompanionMap || {},
                  extraTrackingPixels: stagedTag.extraTrackingPixels || [],
                };
                commitCreativesPlacements.push(placement);
              }
            }
          }
          if (commitCreativesPlacements.length) {
            await CreativeLambdaFetch("/commitCreatives", {
              method: "POST",
              body: { company, placements: commitCreativesPlacements },
            });
            // Send Creative Rotation Updates to Flashtalking
            const returnedCreativesResponse = await FlashtalkingLambdaFetch(
              "/sendCreativeRotationsToFlashtalking",
              {
                method: "POST",
                body: {
                  company,
                  placementMap: returnedPlacementMap,
                },
              }
            );
            const returnedCreatives = await awaitJSON(returnedCreativesResponse);
            for (const creativeID of R.keys(returnedCreatives)) {
              const creative = returnedCreatives[creativeID];
              if (creative.error) {
                creativeErrors.push(creative.error);
              }
            }
          }
        }
        let errorText = "";
        for (const key of R.keys(R.defaultTo({}, returnedPlacementMap))) {
          if (returnedPlacementMap[key].error) {
            errorText = `${errorText}Error: ${returnedPlacementMap[key].error}\n`;
          }
        }
        for (const error of creativeErrors) {
          errorText = `${errorText}Error: ${error}\n`;
        }

        if (errorText) {
          setError({ title: "Error", message: errorText });
        } else {
          setIsSendingTagsToAdServer(SendingState.REFRESHING_DATA);
          setError({
            title: "Success",
            variant: "success",
            message: "Placements uploaded!",
          });
        }
      }
    } catch (e) {
      setError({
        message: e.message,
        reportError: e,
      });
      setIsSendingTagsToAdServer(SendingState.COMPLETE);
    }
  }, [company, creativeMap, flashtalkingSites, setError, stagedTags]);

  const sendTagsToAdServers = useCallback(async () => {
    await sendTagsToER();
    await sendTagsToFlashTalking();
  }, [sendTagsToER, sendTagsToFlashTalking]);

  const sendTagsToNetworks = useCallback(async () => {
    setIsSendingTagsToAdServer(SendingState.SENDING);
    try {
      const placementMap = {};
      for (const key of R.keys(selectedTagsForNetworkSendMap)) {
        const tag = selectedTagsForNetworkSendMap[key];
        if (!R.isNil(tag)) {
          placementMap[key] = {
            campaign_id: tag.campaignID,
            campaign_name: tag.campaignName,
            company: company,
            derived_id: tag.derivedID,
            placement_id: tag.placement_id,
            placement_name: tag.placement_name,
            placement_type: tag.flashtalkingPlacementType,
            platform: tag.platform,
            start_date: R.defaultTo(TODAY, tag.startDate).slice(0, 10),
            vendor: tag.placementVendor,
          };
        }
      }
      await FlashtalkingLambdaFetch("/sendFlashtalkingPlacementTagsToNetworks", {
        method: "POST",
        body: { placementMap },
      });
      setError({
        title: "Success",
        variant: "success",
        message: "Placement Tags Sent!",
      });
    } catch (e) {
      setError({
        message: e.message,
        reportError: e,
      });
    } finally {
      setIsSendingTagsToAdServer(SendingState.COMPLETE);
      setShowSendTagsToNetworksModal(false);
    }
  }, [company, selectedTagsForNetworkSendMap, setError]);

  useEffect(() => {
    // Force the order of refreshing page data then clearing staged tags so that
    // there's no glitchiness in which staged tags reappear in the missing
    // tags list after they're sent.
    if (isSendingTagsToAdServer === SendingState.REFRESHING_DATA) {
      setShouldRefreshData(true); // Trigger data refresh in StreamingOrderStatus component.
      setIsSendingTagsToAdServer(SendingState.CLEARING_STAGED_TAGS);
    }
    if (isSendingTagsToAdServer === SendingState.CLEARING_STAGED_TAGS && !shouldRefreshData) {
      setStagedTags([]);
      setIsSendingTagsToAdServer(SendingState.COMPLETE);
    }
  }, [isSendingTagsToAdServer, setShouldRefreshData, shouldRefreshData]);

  // Cache staged tags in the browser so they stick around on refresh.
  useEffect(() => {
    localStorage.setItem(
      `${company}_staged_tags`,
      JSON.stringify({ value: stagedTags, date: new Date() })
    );
  }, [company, stagedTags]);

  const audioCompanionOptions = useAudioCompanionOptions();

  const modalHeader = (
    <Modal.Header>
      <Modal.Title>Send Flashtalking Tags to Networks</Modal.Title>
    </Modal.Header>
  );

  const modalBody = (
    <Modal.Body>
      <ListGroup>
        {R.map(
          tag => (
            <ListGroup.Item>
              <div className="ftPlacementRowForNetworkSend">
                <CheckBox
                  checked={!R.isNil(selectedTagsForNetworkSendMap[tag.placement_id])}
                  onCheck={() =>
                    setSelectedTagsForNetworkSendMap(map => ({
                      ...map,
                      [tag.placement_id]: R.isNil(selectedTagsForNetworkSendMap[tag.placement_id])
                        ? tag
                        : null,
                    }))
                  }
                />
                <div
                  onClick={() =>
                    setSelectedTagsForNetworkSendMap(map => ({
                      ...map,
                      [tag.placement_id]: R.isNil(selectedTagsForNetworkSendMap[tag.placement_id])
                        ? tag
                        : null,
                    }))
                  }
                >
                  <div>Start Date: {R.defaultTo("", tag.startDate).slice(0, 10)}</div>
                  <div>Placement ID: {tag.placement_id}</div>
                  <div>Placement Name: {tag.placement_name}</div>
                  <div>Vendor: {tag.placementVendor}</div>
                  <div>Campaign ID: {tag.campaignID}</div>
                  <div>Campaign Name: {tag.campaignName}</div>
                  <div>
                    Placement Type:{" "}
                    {tag.flashtalkingPlacementType === "Multi-tracker"
                      ? "Pixel"
                      : tag.flashtalkingPlacementType}
                  </div>
                </div>
              </div>
            </ListGroup.Item>
          ),
          R.filter(
            tag => (tag.adServer === "ft" || tag.adServer === "Flashtalking") && tag.hasPlacement,
            R.defaultTo([], filteredTagInfo)
          )
        )}
      </ListGroup>
    </Modal.Body>
  );

  const modalFooter = (
    <Modal.Footer>
      <Button onClick={() => setShowSendTagsToNetworksModal(false)}>Cancel</Button>
      <Button
        onClick={() => {
          setShowSendTagsToNetworksModal(false);
          setAreYouSure({
            title: "You're sending Flashtalking Tags to Networks",
            message:
              "You are about to email these placements tags directly to their respective networks. Are you sure you want to continue?",
            variant: "warning",
            cancelText: "Never mind",
            okayText: "Yes",
            onCancel: () => setShowSendTagsToNetworksModal(true),
            onOkay: sendTagsToNetworks,
          });
        }}
      >
        Send
      </Button>
    </Modal.Footer>
  );

  const sendTagsToNetworksModal = (
    <Modal centered onHide={() => setShowSendTagsToNetworksModal(false)} show size="lg">
      {modalHeader}
      {modalBody}
      {modalFooter}
    </Modal>
  );

  return (
    <TagCreationContext.Provider
      value={{
        audioCompanionOptions,
      }}
    >
      {showSendTagsToNetworksModal && sendTagsToNetworksModal}
      <div className="tagView">
        <div className="controlBar">
          <IconButton
            variant={`${onlyMissing ? "" : "outline-"}primary`}
            onClick={() => setOnlyMissing(R.not)}
            icon={onlyMissing ? <MdDone /> : <MdCheckBoxOutlineBlank />}
          >
            Missing Tags Only ({missingCount})
          </IconButton>
          <OldFilterBar
            options={GENERATE_FILTER_BAR_OPTIONS}
            lines={filteredTagInfo}
            onFilter={setFilter}
          />
        </div>
        <div className="content">
          <ListGroup>
            {filteredLines.map(tags => {
              const derivedID = R.path([0, "derivedID"], tags);
              return (
                <TagRow
                  key={derivedID}
                  tags={tags}
                  suppressTag={suppressTag}
                  deviceMap={
                    R.path([derivedID, "deviceMap"], formChanges) || {
                      [DeviceNames.MOBILE]: true,
                      [DeviceNames.CTV]: true,
                      [DeviceNames.DESKTOP]: true,
                    }
                  }
                  setDeviceMap={map => {
                    setFormFail(false);
                    R.pipe(R.assocPath([derivedID, "deviceMap"]), setFormChanges)(map);
                  }}
                  osMap={
                    R.path([derivedID, "osMap"], formChanges) || {
                      [OSNames.ANDROID]: true,
                      [OSNames.IOS]: true,
                      [OSNames.DESKTOP]: true,
                    }
                  }
                  setOSMap={map => {
                    setFormFail(false);
                    R.pipe(R.assocPath([derivedID, "osMap"]), setFormChanges)(map);
                  }}
                  lengthMap={R.path([derivedID, "lengthMap"], formChanges) || {}}
                  setLengthMap={map => {
                    setFormFail(false);
                    R.pipe(R.assocPath([derivedID, "lengthMap"]), setFormChanges)(map);
                  }}
                  vendor={R.path([derivedID, "vendor"], formChanges) || ""}
                  setVendor={vendor => {
                    setFormFail(false);
                    R.pipe(R.assocPath([derivedID, "vendor"]), setFormChanges)(vendor);
                  }}
                  formFail={formFail}
                  onAdd={openTagCreationModal}
                />
              );
            })}
          </ListGroup>
          <TagStagingArea
            stagedTags={stagedTags}
            setStagedTags={setStagedTags}
            isSending={isSendingTagsToAdServer !== SendingState.COMPLETE}
            openTagCreationModal={openTagCreationModal}
          />

          <div className="generateBar">
            <Button onClick={() => setShowSendTagsToNetworksModal(true)}>
              Send Flashtalking Tags to Networks
            </Button>
            <Button
              onClick={() =>
                setAreYouSure({
                  title: "You're sending an update to Extreme Reach/Flashtalking",
                  message:
                    "You are about to upload these new placements directly to Extreme Reach/Flashtalking. Are you sure you want to continue?",
                  variant: "warning",
                  cancelText: "Never mind",
                  okayText: "Yes",
                  onOkay: sendTagsToAdServers,
                })
              }
              disabled={isSendingTagsToAdServer !== SendingState.COMPLETE}
            >
              Send Tags to Extreme Reach/Flashtalking
            </Button>
          </div>
          {tagCreationModalData && (
            <TagCreationModal
              initialTag={tagCreationModalData}
              setInitialTag={setTagCreationModalData}
              show={showTagCreationModal}
              startingPage={"initialForm"}
              onClose={closeTagCreationModal}
              stageTags={stageTags}
              isEditMode={isTagCreationModalEditMode}
              flashtalkingCampaigns={flashtalkingCampaigns}
            />
          )}
        </div>
      </div>
    </TagCreationContext.Provider>
  );
};

export default GenerateView;
