import React, { useCallback, useState } from "react";
import { groupByAndSum, sortData, unnestPacingData } from "./pacingUtils";
import PacingBar from "./PacingBar";
import {
  Button as BPMButton,
  ButtonType,
  DownloadDropdown,
  IconToggleButton,
  TextToggleButton,
} from "../Components";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { MdAutoGraph, MdOutlineTableRows, MdShowChart } from "react-icons/md";
import CollapseButton from "../Components/CollapseButton";
import { Container, Modal, Col, Row } from "react-bootstrap";
import ModalCustomLegend from "./ModalCustomLegend";
import { downloadPNG, exportToExcel } from "../utils/download-utils";
import ModalCumulativeAndDailyGraph from "./ModalCumulativeAndDailyGraph";
import "./PacingBarsDashboard.scss";
import ModalCumulativeAndDailyTable from "./ModalCumulativeAndDailyTable";

interface PacingBarsDashboardProps {
  data: any[];
  keys: string[];
  simplicity: string;
  goalTypeValue: string;
  marginLeft: number;
  segmentVisibility: { [key: string]: boolean };
  toggleSegmentVisibility: (key: string) => void;
  longestText: number;
  sortOption: string;
  total?: boolean;
}

const PacingBarsDashboard: React.FC<PacingBarsDashboardProps> = ({
  data,
  keys,
  simplicity,
  goalTypeValue,
  marginLeft,
  segmentVisibility,
  toggleSegmentVisibility,
  longestText,
  sortOption,
  total = false,
}) => {
  const [activeModal, setActiveModal] = useState<String>("");
  const [displayMode, setDisplayMode] = useState("Cumulative");
  const [graphView, setGraphView] = useState(true);

  const excelDownloadPacingData = useCallback(() => {
    exportToExcel(unnestPacingData(data), "pacingData");
  }, [data]);

  const pngDownloadPacingData = useCallback(async () => {
    await downloadPNG(".modal-body .container", "pacingChart");
  }, []);

  if (keys.length === 0) {
    return null;
  }

  const currentKey = keys[0];
  const remainingKeys = keys.slice(1);
  const groupedData = groupByAndSum(data, [currentKey]);
  const sortedGroupedData = sortData(groupedData, sortOption, currentKey);
  const propertiesToGroupGraph = [currentKey, "date"];
  const today = new Date();

  return (
    <div className={keys.length === 1 && marginLeft === 1 ? "dashboardChartContainer" : ""}>
      {sortedGroupedData.map((group, index) => (
        <div
          key={index}
          className={marginLeft === 1 && keys.length > 1 ? "dashboardChartContainer" : ""}
        >
          <div key={index}>
            {marginLeft === 1 && keys.length > 1 && (
              <div className="chartsContainerBodyHeader">
                <div className="containerBodyHeaderTitle">{group[currentKey]}</div>
                <CollapseButton onClick={() => toggleSegmentVisibility(group[currentKey])} />
              </div>
            )}
            {marginLeft > 1 && (
              <div hidden>
                {" "}
                <div>{group[currentKey]}</div>
                <CollapseButton onClick={() => toggleSegmentVisibility(group[currentKey])} />
              </div>
            )}
            <div className="chartsContainerBody">
              <PacingBar
                className="simpleBarChart"
                pacingData={{
                  totalCleared: group.cleared[simplicity][goalTypeValue],
                  totalProjected:
                    new Date(group.date) < today ? 0 : group.projected[simplicity][goalTypeValue],
                  totalRemainder:
                    Number(group.booked[simplicity][goalTypeValue]) -
                    Number(group.cleared[simplicity][goalTypeValue]),
                  segmentName: group[currentKey],
                  budget: group.booked[simplicity][goalTypeValue],
                }}
                keys={["totalCleared", "totalProjected", "totalRemainder"]}
                marginLeft={marginLeft}
                numberOfSegments={keys}
                longestText={longestText}
                total={total}
                currentSegment={currentKey}
              />
              <div className="buttonGraph">
                <BPMButton
                  size="lg"
                  type={ButtonType.FILLED}
                  variant={ButtonFrameworkVariant.LEADING_ICON}
                  design="secondary"
                  icon={<MdAutoGraph />}
                  onClick={() => setActiveModal(group[currentKey])}
                >
                  Details
                </BPMButton>
              </div>
            </div>
            {sortedGroupedData.length - 1 !== index || keys.length > 1 ? (
              <div className={`lineAtBot${marginLeft}`}></div>
            ) : null}
            {!segmentVisibility[group[currentKey]] && (
              <PacingBarsDashboard
                data={data.filter(item => item[currentKey] === group[currentKey])}
                keys={remainingKeys}
                simplicity={simplicity}
                goalTypeValue={goalTypeValue}
                marginLeft={marginLeft + 1}
                segmentVisibility={segmentVisibility}
                toggleSegmentVisibility={toggleSegmentVisibility}
                longestText={longestText}
                sortOption={sortOption}
              />
            )}
            {activeModal === group[currentKey] && (
              <Modal
                show={true}
                onHide={() => setActiveModal("")}
                size="xl"
                className="pacingModal"
              >
                {" "}
                <Modal.Header closeButton>
                  <div className="pacingModalHeader">
                    <div>{group[currentKey]}</div>
                    <div className="pacingModalHeaderButtons">
                      <TextToggleButton
                        design="primary"
                        options={["Cumulative", "Daily"]}
                        selectedOption={displayMode}
                        onChange={value => {
                          setDisplayMode(value);
                        }}
                      />
                      <IconToggleButton
                        options={[
                          { key: "showTable", icon: <MdOutlineTableRows />, label: "table view" },
                          { key: "showGraph", icon: <MdShowChart />, label: "graph view" },
                        ]}
                        size="sm"
                        selectedOption={graphView ? "showGraph" : "showTable"}
                        onChange={() => setGraphView(prev => !prev)}
                      />
                      <DownloadDropdown
                        size="sm"
                        onClickOptions={[excelDownloadPacingData, pngDownloadPacingData]}
                      />
                    </div>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  {graphView ? (
                    <Container>
                      <Row xs={1} md={10}>
                        <PacingBar
                          className="simpleBarChart"
                          pacingData={{
                            totalCleared: group.cleared[simplicity][goalTypeValue],
                            totalProjected:
                              new Date(group.date) < today
                                ? 0
                                : group.projected[simplicity][goalTypeValue],
                            totalRemainder:
                              Number(group.booked[simplicity][goalTypeValue]) -
                              Number(group.cleared[simplicity][goalTypeValue]),
                            segmentName: group[currentKey],
                            budget: group.booked[simplicity][goalTypeValue],
                          }}
                          keys={["totalCleared", "totalProjected", "totalRemainder"]}
                          marginLeft={marginLeft}
                          numberOfSegments={keys}
                          isModal={true}
                          longestText={longestText}
                          currentSegment={currentKey}
                        />
                      </Row>
                      <Row xs={10} md={10}>
                        <Col xs={10} md={10}>
                          <ModalCumulativeAndDailyGraph
                            data={groupByAndSum(
                              data.filter(item => item[currentKey] === group[currentKey]),
                              propertiesToGroupGraph
                            )}
                            today={new Date().toDateString()}
                            showCumulative={displayMode === "Cumulative"}
                            budget={group.booked[simplicity][goalTypeValue]}
                          ></ModalCumulativeAndDailyGraph>
                        </Col>
                        <Col xs={2} md={2}>
                          <ModalCustomLegend
                            graphView={displayMode === "Cumulative"}
                          ></ModalCustomLegend>
                        </Col>
                      </Row>
                    </Container>
                  ) : (
                    <Container style={{ height: "400px" }}>
                      <ModalCumulativeAndDailyTable
                        data={groupByAndSum(
                          data.filter(item => item[currentKey] === group[currentKey]),
                          propertiesToGroupGraph
                        )}
                        showCumulative={displayMode === "Cumulative"}
                        budget={group.booked[simplicity][goalTypeValue]}
                      ></ModalCumulativeAndDailyTable>
                    </Container>
                  )}
                </Modal.Body>
              </Modal>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PacingBarsDashboard;
