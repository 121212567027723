import React, { useState, useMemo, useEffect, useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import cn from "classnames";
import { useRenderedPosition } from "../../utils/hooks/useDOMHelpers";
import { DateRange, Setter } from "../../utils/types";
import "./DatePicker.scss";
import { DatePickerButton } from "./DatePickerButton";
import { DPCalendar } from "./DPCalendar";
import { CustomDatePresets, CustomPresetState } from "./CustomDatePresets";
import { Button, ButtonType } from "../Button";
import { ToggleSwitch } from "../ToggleSwitch";
import { DATE_FORMAT } from "../../utils/data";
import DateInputField from "./DateInputField/DateInputField";
import { MdArrowForwardIos } from "react-icons/md";
import {
  CALENDAR_HEIGHT,
  CALENDAR_SPACE,
  CALENDAR_WIDTH,
  COMP_CALENDAR_HEIGHT,
  COMP_PER_TYPES,
  DATE_INTERVALS,
  DATE_PRESETS,
  DEFAULT_IS_DAYBLOCKED,
  DEFAULT_IS_OUTSIDE_RANGE,
  DEFAULT_MAX_YEAR,
  DEFAULT_MIN_YEAR,
  FOCUS_OPTIONS,
  GRAPH_INTERVALS_WIDTH,
  INIT_CUSTOM_PRESET_STATE,
  PRESETS_WIDTH,
} from "./DatePickerButton/DatePickerConstants";

interface SingleDatePickerProps {
  range?: DateRange;
  onChange: (newDates: DateRange) => void;
  minYear?: number;
  maxYear?: number;
  isOutsideRange?: (date: string) => boolean;
  isDayBlocked?: (date: string) => boolean;
  bordered?: boolean;
  currentMonthSecond?: boolean;
  fullWeeksOnly?: boolean; // Used for linear pages in which we only allow selection of entire weeks starting on Mondays.
  maxDate?: string; // Used for brand health page to explain why certain dates are greyed out.
  dateInterval?: boolean;
  dateIntervalOnChage?: (param: string) => void;
  datePresets?: boolean;
  weekDefinition?: number;
}

interface ComparisonDatePickerProps extends SingleDatePickerProps {
  comparison: true;
  comparisonRange: DateRange | undefined;
  comparisonOnChange: (newDates: DateRange) => void;
  disableComparisonSetter?: Setter<boolean>;
}

type DatePickerProps =
  | (SingleDatePickerProps & {
      comparisonRange?: never;
      comparisonOnChange?: never;
      comparison?: never;
      disableComparisonSetter?: never;
    })
  | ComparisonDatePickerProps;

export const DatePicker: React.FC<DatePickerProps> = ({
  range,
  onChange,
  minYear = DEFAULT_MIN_YEAR,
  maxYear = DEFAULT_MAX_YEAR,
  isOutsideRange = DEFAULT_IS_OUTSIDE_RANGE,
  isDayBlocked = DEFAULT_IS_DAYBLOCKED,
  currentMonthSecond,
  fullWeeksOnly,
  maxDate,
  dateInterval = false,
  datePresets = false,
  comparison = false,
  comparisonRange,
  comparisonOnChange,
  weekDefinition = 1,
  dateIntervalOnChage,
  disableComparisonSetter,
}) => {
  // Years for the year picker
  const years = useMemo(() => R.range(minYear, maxYear + 1), [minYear, maxYear]);
  const { start, end } = range || {};
  const { start: comparisonStart, end: comparisonEnd } = comparisonRange || {};
  const ref = useRef<HTMLDivElement>(null);
  const [position, resetPosition] = useRenderedPosition(ref);
  const [show, setShowRaw] = useState(false);
  const [dateInputs, setDateInputs] = useState<Partial<DateRange>>({
    start,
    end,
  });
  const [comparisonDateInputs, setComparisonDateInputs] = useState<Partial<DateRange>>({
    start: comparisonStart,
    end: comparisonEnd,
  });
  const [focus, setFocus] = useState<typeof FOCUS_OPTIONS[number]>("START");
  const [comparisonFocus, setComparisonFocus] = useState<typeof FOCUS_OPTIONS[number]>("START");
  const [selectedInterval, setSelectedInterval] = useState<typeof DATE_INTERVALS[number] | null>();
  const [selectedPreset, setSelectedPreset] = useState<
    typeof DATE_PRESETS[number][number] | null
  >();
  const [selectedCompPerType, setSelectedCompPerType] = useState<
    typeof COMP_PER_TYPES[number] | null
  >();
  const [comparisonEnabled, setComparisonEnabled] = useState<boolean>(true);
  const [startCustomPreset, setStartCustomPreset] = useState<CustomPresetState>({
    ...INIT_CUSTOM_PRESET_STATE,
  });
  const [endCustomPreset, setEndCustomPreset] = useState<CustomPresetState>({
    ...INIT_CUSTOM_PRESET_STATE,
  });
  const [startCompCustomPreset, setStartCompCustomPreset] = useState<CustomPresetState>({
    ...INIT_CUSTOM_PRESET_STATE,
  });
  const [endCompCustomPreset, setEndCompCustomPreset] = useState<CustomPresetState>({
    ...INIT_CUSTOM_PRESET_STATE,
  });
  const [compOpen, setCompOpen] = useState<boolean>(false);
  const [rangeErrorPreset, setRangeErrorPreset] = useState<"start" | "end" | null>(null);
  const [compRangeErrorPreset, setCompRangeErrorPreset] = useState<"start" | "end" | null>(null);

  const resolvedPosition = useMemo(() => {
    // Start off anchored below the input aligned on the right
    const combinedWidth =
      CALENDAR_WIDTH + (datePresets ? PRESETS_WIDTH : dateInterval ? GRAPH_INTERVALS_WIDTH : 0);
    const combinedHeight = comparison ? COMP_CALENDAR_HEIGHT : CALENDAR_HEIGHT;
    let top = position.bottom + CALENDAR_SPACE;
    let left = position.right - combinedWidth;
    const overlayStyle: { height?: string; width?: string } = {};
    // If it spills off the bottom of the page, open up
    if (top + combinedHeight > window.innerHeight) {
      top = CALENDAR_SPACE;
      if (top + combinedHeight > window.innerHeight) {
        overlayStyle.height = `${top + combinedHeight + CALENDAR_SPACE}px`;
      }
    }
    // search for a better spot
    if (left < 0) {
      ({ left } = position);
      if (left + combinedWidth > window.innerWidth) {
        left = window.innerWidth / 2 - combinedWidth / 2;
        if (left + combinedWidth > window.innerWidth) {
          left = CALENDAR_SPACE;
          if (left + combinedWidth > window.innerWidth) {
            overlayStyle.width = `${left + combinedWidth + CALENDAR_SPACE}px`;
          }
        }
      }
    }
    return { position: { top, left }, overlay: overlayStyle };
  }, [position, dateInterval, datePresets, comparison]);

  const resolvedIsOutsideValidRange = useCallback(
    (date: string) => {
      const invalidFormat = !Dfns.isValid(Dfns.parseISO(date));
      if (invalidFormat) {
        return true;
      }
      const inputYear = Dfns.getYear(Dfns.parseISO(date));
      const outsideYearRange = inputYear < years[0] || inputYear > years[years.length - 1];
      return isOutsideRange(date) || isDayBlocked(date) || outsideYearRange || invalidFormat;
    },
    [isOutsideRange, isDayBlocked, years]
  );

  const resetState = useCallback(() => {
    setSelectedInterval(null);
    setSelectedPreset(null);
    setSelectedCompPerType(null);
    setStartCustomPreset({ ...INIT_CUSTOM_PRESET_STATE });
    setEndCustomPreset({ ...INIT_CUSTOM_PRESET_STATE });
    setStartCompCustomPreset({ ...INIT_CUSTOM_PRESET_STATE });
    setEndCompCustomPreset({ ...INIT_CUSTOM_PRESET_STATE });
    setCompOpen(false);
    setRangeErrorPreset(null);
    setCompRangeErrorPreset(null);
  }, []);

  const setShow = useCallback(
    (show: boolean, save?: boolean) => {
      resetPosition();
      setShowRaw(show);
      if (!show && dateInputs.start && dateInputs.end) {
        setFocus("START");
        setDateInputs({ start, end });
        if (comparison) {
          setComparisonDateInputs({
            start: comparisonStart,
            end: comparisonEnd,
          });
          if (disableComparisonSetter) {
            disableComparisonSetter(!comparisonEnabled);
          }
          setComparisonEnabled(true);
        }

        if (save && (dateInputs.start !== start || dateInputs.end !== end)) {
          onChange(dateInputs as DateRange);
        }

        if (
          comparison &&
          save &&
          comparisonOnChange &&
          comparisonDateInputs &&
          (comparisonDateInputs.start !== comparisonStart ||
            comparisonDateInputs.end !== comparisonEnd)
        ) {
          comparisonOnChange(comparisonDateInputs as DateRange);
        }
      } else if (show && disableComparisonSetter) {
        disableComparisonSetter(false);
      }
      if (!show) {
        resetState();
      }
    },
    [
      onChange,
      dateInputs,
      resetPosition,
      start,
      end,
      comparison,
      comparisonDateInputs,
      comparisonEnd,
      comparisonStart,
      comparisonOnChange,
      comparisonEnabled,
      disableComparisonSetter,
      resetState,
    ]
  );

  useEffect(() => {
    setComparisonDateInputs(dateInputs => {
      let newDateInputs = { ...dateInputs };
      let changed = false;
      if (dateInputs.start !== comparisonStart) {
        newDateInputs.start = comparisonStart;
        changed = true;
      }
      if (dateInputs.end !== comparisonEnd) {
        newDateInputs.end = comparisonEnd;
        changed = true;
      }
      if (changed) {
        return newDateInputs;
      }
      return dateInputs;
    });
  }, [comparisonRange, comparisonStart, comparisonEnd]);

  useEffect(() => {
    setDateInputs(dateInputs => {
      let newDateInputs = { ...dateInputs };
      let changed = false;
      if (dateInputs.start !== start) {
        newDateInputs.start = start;
        changed = true;
      }
      if (dateInputs.end !== end) {
        newDateInputs.end = end;
        changed = true;
      }
      if (changed) {
        return newDateInputs;
      }
      return dateInputs;
    });
  }, [start, end]);

  const onChangeInternal = useCallback(
    (dateRange: Partial<DateRange>, focus: typeof FOCUS_OPTIONS[number]) => {
      setDateInputs(dateRange);
      setFocus(focus);
    },
    []
  );

  const onChangeComparisonInternal = useCallback(
    (dateRange: Partial<DateRange>, focus: typeof FOCUS_OPTIONS[number]) => {
      setComparisonDateInputs(dateRange);
      setComparisonFocus(focus);
    },
    []
  );

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setShow(false);
      }
    };
    document.addEventListener("keydown", handler);
    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, [setShow]);

  const onDatePresetSelect = useCallback(
    preset => {
      const now = new Date();
      const yesterdayDate = Dfns.subDays(1, now);
      const yesterdayString = Dfns.format(DATE_FORMAT, Dfns.subDays(1, now));

      let calculatedStart;
      let calculatedEnd;
      calculatedEnd = yesterdayString;
      switch (preset) {
        case undefined:
          calculatedStart = start;
          calculatedEnd = end;
          break;
        case "Previous 7 days":
          calculatedStart = Dfns.format(DATE_FORMAT, Dfns.subDays(6, yesterdayDate));
          break;
        case "Previous 30 days":
          calculatedStart = Dfns.format(DATE_FORMAT, Dfns.subDays(29, yesterdayDate));
          break;
        case "Previous 90 days":
          calculatedStart = Dfns.format(DATE_FORMAT, Dfns.subDays(89, yesterdayDate));
          break;
        case "Previous 365":
          calculatedStart = Dfns.format(
            DATE_FORMAT,
            Dfns.addDays(1, Dfns.subYears(1, yesterdayDate))
          );
          break;
        case "Last Week":
          const lastWeek = Dfns.subWeeks(1, now);
          const startOfWeek = Dfns.startOfWeekWithOptions({ weekStartsOn: weekDefinition });
          const endOfWeek = Dfns.lastDayOfWeekWithOptions({ weekStartsOn: weekDefinition });
          calculatedStart = Dfns.format(DATE_FORMAT, startOfWeek(lastWeek));
          calculatedEnd = Dfns.format(DATE_FORMAT, endOfWeek(lastWeek));
          break;
        case "Last Month":
          const lastMonth = Dfns.subMonths(1, now);
          calculatedStart = Dfns.format(DATE_FORMAT, Dfns.startOfMonth(lastMonth));
          calculatedEnd = Dfns.format(DATE_FORMAT, Dfns.lastDayOfMonth(lastMonth));
          break;
        case "Last Quarter":
          const lastQuarter = Dfns.subQuarters(1, now);
          calculatedStart = Dfns.format(DATE_FORMAT, Dfns.startOfQuarter(lastQuarter));
          calculatedEnd = Dfns.format(DATE_FORMAT, Dfns.lastDayOfQuarter(lastQuarter));
          break;
        case "Last Year":
          const lastYear = Dfns.subYears(1, now);
          calculatedStart = Dfns.format(DATE_FORMAT, Dfns.startOfYear(lastYear));
          calculatedEnd = Dfns.format(DATE_FORMAT, Dfns.lastDayOfYear(lastYear));
          break;
        case "YTD":
          calculatedStart = Dfns.format(DATE_FORMAT, Dfns.startOfYear(yesterdayDate));
          break;
      }

      const newrange = {
        start: calculatedStart,
        end: calculatedEnd,
      };

      setDateInputs(newrange);
    },
    [weekDefinition, start, end]
  );

  useEffect(() => {
    let calculatedStart;
    let calculatedEnd;
    const { start, end } = dateInputs;
    if (!start || !end) {
      return;
    }
    const parsedStart = Dfns.parseISO(start);
    const parsedEnd = Dfns.parseISO(end);
    switch (selectedCompPerType) {
      case undefined:
        calculatedStart = comparisonStart;
        calculatedEnd = comparisonEnd;
        break;
      case "Custom":
        return;
      case "Same Time Last Year":
        calculatedStart = Dfns.format(DATE_FORMAT, Dfns.subYears(1, parsedStart));
        calculatedEnd = Dfns.format(DATE_FORMAT, Dfns.subYears(1, parsedEnd));
        break;
      case "Default":
        calculatedEnd = Dfns.format(DATE_FORMAT, Dfns.subDays(1, parsedStart));
        const isBeginningAndEndOfMonths =
          Dfns.isLastDayOfMonth(parsedEnd) && Dfns.isFirstDayOfMonth(parsedStart);

        if (isBeginningAndEndOfMonths && Dfns.isSameMonth(parsedStart, parsedEnd)) {
          calculatedStart = Dfns.format(DATE_FORMAT, Dfns.subMonths(1, parsedStart));
        } else if (
          isBeginningAndEndOfMonths &&
          Dfns.differenceInMonths(parsedStart, parsedEnd) === 2
        ) {
          calculatedStart = Dfns.format(DATE_FORMAT, Dfns.subQuarters(1, parsedStart));
        } else if (
          isBeginningAndEndOfMonths &&
          Dfns.differenceInMonths(parsedStart, parsedEnd) === 11
        ) {
          calculatedStart = Dfns.format(DATE_FORMAT, Dfns.subYears(1, parsedStart));
        } else {
          const primaryDifferenceInDays = Dfns.differenceInDays(parsedStart, parsedEnd);
          calculatedStart = Dfns.format(
            DATE_FORMAT,
            Dfns.subDays(primaryDifferenceInDays + 1, parsedStart)
          );
        }
    }

    if (calculatedStart && calculatedEnd) {
      const newrange = {
        start: calculatedStart,
        end: calculatedEnd,
      };
      setComparisonDateInputs(newrange);
    }
  }, [dateInputs, comparisonEnd, comparisonStart, selectedCompPerType]);

  return (
    <>
      <DatePickerButton
        reference={ref}
        setShow={setShow}
        range={range}
        fullWeeksOnly={fullWeeksOnly}
        setFocus={setFocus}
        comparison={comparison}
        comparisonRange={comparisonRange}
      ></DatePickerButton>
      {show &&
        ReactDOM.createPortal(
          <div className="DPDateContainer">
            <div
              className="overlay"
              onClick={() => setShow(false)}
              style={resolvedPosition.overlay}
            />
            <div className="calendarBox" style={resolvedPosition.position}>
              <div className="DPheader">
                <div className="headerTitleText">{"Primary Date Range"}</div>
                <div className="rightHeaderItems">
                  <div className="dateDisplay">
                    <DateInputField
                      label="Start"
                      value={dateInputs.start || ""}
                      setValue={(value: string) => {
                        setDateInputs(d =>
                          !d.end || value > d.end ? { start: value } : { ...d, start: value }
                        );
                      }}
                      invalidateDateOrRange={resolvedIsOutsideValidRange}
                      clearPreset={() => setSelectedPreset(null)}
                    />
                    <DateInputField
                      label="End"
                      value={dateInputs.end || ""}
                      setValue={(value: string) => {
                        setDateInputs(d =>
                          !d.start || value < d.start ? { end: value } : { ...d, end: value }
                        );
                      }}
                      invalidateDateOrRange={resolvedIsOutsideValidRange}
                      clearPreset={() => setSelectedPreset(null)}
                    />
                  </div>
                </div>
              </div>
              <div className="calendarAndControls primary">
                <DPCalendar
                  key={"primaryDPCalendar"}
                  start={dateInputs.start}
                  end={dateInputs.end}
                  focus={focus}
                  onChange={onChangeInternal}
                  isOutsideRange={isOutsideRange}
                  isDayBlocked={isDayBlocked}
                  years={years}
                  currentMonthSecond={currentMonthSecond}
                  maxDate={maxDate}
                />
                {(datePresets || dateInterval) && (
                  <div className="controls">
                    {datePresets && (
                      <div className="singleControlContainer">
                        <div className="controlSectionLabel">
                          <div className="sectionLabelWrapper">
                            Presets <span>(Optional)</span>
                          </div>
                        </div>
                        <div className="datePresetSection">
                          <div className="standardPresets">
                            {DATE_PRESETS.map((group, i) => (
                              <div
                                className="datePresetOptionsContainer"
                                key={`DPPresetOptionContainer${i}`}
                              >
                                {group.map((preset, j) => (
                                  <Button
                                    key={`DPPresetOptionButton${i}${j}`}
                                    type={ButtonType.EMPTY}
                                    design="secondary"
                                    size="sm"
                                    onClick={() => {
                                      setSelectedPreset(p => {
                                        const setValue = p === preset ? undefined : preset;
                                        onDatePresetSelect(setValue);
                                        return setValue;
                                      });
                                    }}
                                    className={cn("controlButton", "datePresetButton", {
                                      selected: selectedPreset === preset,
                                    })}
                                  >
                                    {preset}
                                  </Button>
                                ))}
                              </div>
                            ))}
                          </div>
                          <div className="customPresets">
                            <Button
                              key={"DPPresetOptionButtonPrimaryCustom"}
                              type={ButtonType.EMPTY}
                              design="secondary"
                              size="sm"
                              onClick={() => {
                                setSelectedPreset(p => {
                                  const setValue = p === "Custom" ? undefined : "Custom";
                                  if (!setValue) {
                                    onDatePresetSelect(setValue);
                                  }
                                  return setValue;
                                });
                              }}
                              className={cn("controlButton", "datePresetButton", {
                                selected: selectedPreset === "Custom",
                              })}
                            >
                              {"Custom"}
                            </Button>
                            <CustomDatePresets
                              setRangeError={setRangeErrorPreset}
                              setDateInputs={setDateInputs}
                              isValidInRange={resolvedIsOutsideValidRange}
                              weekDefinition={weekDefinition}
                              startCustomPreset={startCustomPreset}
                              setStartCustomPreset={setStartCustomPreset}
                              endCustomPreset={endCustomPreset}
                              setEndCustomPreset={setEndCustomPreset}
                              disabled={selectedPreset !== "Custom"}
                              rangeError={rangeErrorPreset}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {dateInterval && (
                      <div className="singleControlContainer">
                        <div className="controlSectionLabel">Graph Intervals</div>
                        {DATE_INTERVALS.map((interval, i) => (
                          <Button
                            key={`DPIntervalOptionButton${i}`}
                            type={ButtonType.EMPTY}
                            design="secondary"
                            size="sm"
                            onClick={() => {
                              setSelectedInterval(i => (i === interval ? undefined : interval));
                              // TODO: make enable this feature
                              // dateIntervalOnChage(interval);
                            }}
                            className={cn("controlButton", {
                              selected: selectedInterval === interval,
                            })}
                          >
                            {interval}
                          </Button>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
              {comparison && comparisonDateInputs && (
                <>
                  <div
                    className={`comHeaderWrapper top ${compOpen ? "compOpen" : "compClosed"}`}
                  ></div>
                  <div
                    className={`DPheader comparisonHeader ${
                      comparisonEnabled ? "" : "compHeadDisabled"
                    }`}
                  >
                    <div className="headerTitleText">
                      {"Comparison Date Range"}
                      {disableComparisonSetter && (
                        <ToggleSwitch
                          label=""
                          onChange={() => setComparisonEnabled(r => !r)}
                          checked={comparisonEnabled}
                          size="lg"
                        />
                      )}
                    </div>
                    <div className="rightHeaderItems">
                      <div
                        className={`dateDisplay comparison ${
                          comparisonEnabled ? "" : "comparisonDisabled"
                        }`}
                        onClick={() => setCompOpen(true)}
                      >
                        <DateInputField
                          label="Comparison Start"
                          value={comparisonDateInputs.start || ""}
                          setValue={(value: string) => {
                            setComparisonDateInputs(d =>
                              !d.end || value < d.end ? { ...d, start: value } : { start: value }
                            );
                          }}
                          invalidateDateOrRange={resolvedIsOutsideValidRange}
                          dashBoarder={true}
                          clearPreset={() => setSelectedCompPerType(null)}
                        />
                        <DateInputField
                          label="Comparison End"
                          value={comparisonDateInputs.end || ""}
                          setValue={(value: string) => {
                            setComparisonDateInputs(d =>
                              !d.start || value > d.start ? { ...d, end: value } : { end: value }
                            );
                          }}
                          invalidateDateOrRange={resolvedIsOutsideValidRange}
                          dashBoarder={true}
                          clearPreset={() => setSelectedCompPerType(null)}
                        />
                      </div>
                      <div
                        className={`DParrow ${comparisonEnabled ? "" : "compDisabledArrow"} ${
                          compOpen ? "" : "comparisonArrowClosed"
                        }`}
                        onClick={() => {
                          if (comparisonEnabled) {
                            setCompOpen(c => !c);
                          }
                        }}
                      >
                        <MdArrowForwardIos />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`comHeaderWrapper ${compOpen ? "" : "compClosed"} ${
                      comparisonEnabled ? "" : "comparisonDisabled"
                    }`}
                  ></div>
                  <div
                    className={`calendarAndControls comparison ${
                      comparisonEnabled ? "" : "comparisonDisabled"
                    } ${compOpen ? "" : "comparisonClosed"}`}
                  >
                    <DPCalendar
                      key={"comparisonDPCalendar"}
                      start={comparisonDateInputs.start}
                      end={comparisonDateInputs.end}
                      focus={comparisonFocus}
                      onChange={onChangeComparisonInternal}
                      isOutsideRange={isOutsideRange}
                      isDayBlocked={isDayBlocked}
                      years={years}
                      currentMonthSecond={currentMonthSecond}
                      maxDate={maxDate}
                      comparison={true}
                    />
                    {(datePresets || dateInterval) && (
                      <div className="controls">
                        {datePresets && (
                          <div className={"singleControlContainer"}>
                            <div className="controlSectionLabel">
                              <div className="sectionLabelWrapper">
                                Presets <span>(Optional)</span>
                              </div>
                            </div>
                            <div className="datePresetSection">
                              <div className="standardPresets">
                                {COMP_PER_TYPES.map((value, i) => (
                                  <Button
                                    key={`DPComaparisonTypeOptionButton${i}`}
                                    type={ButtonType.EMPTY}
                                    design="secondary"
                                    size="sm"
                                    onClick={() => {
                                      setSelectedCompPerType(p =>
                                        p === value ? undefined : value
                                      );
                                    }}
                                    className={cn("controlButton", {
                                      selected: selectedCompPerType === value,
                                    })}
                                  >
                                    {value}
                                  </Button>
                                ))}
                              </div>
                              <div className="customPresets">
                                <Button
                                  key={"DPPresetOptionButtonComparisonCustom"}
                                  type={ButtonType.EMPTY}
                                  design="secondary"
                                  size="sm"
                                  onClick={() => {
                                    setSelectedCompPerType(p =>
                                      p === "Custom" ? undefined : "Custom"
                                    );
                                  }}
                                  className={cn("controlButton", "datePresetButton", {
                                    selected: selectedCompPerType === "Custom",
                                  })}
                                >
                                  {"Custom"}
                                </Button>
                                <CustomDatePresets
                                  setRangeError={setCompRangeErrorPreset}
                                  setDateInputs={setComparisonDateInputs}
                                  isValidInRange={resolvedIsOutsideValidRange}
                                  weekDefinition={weekDefinition}
                                  startCustomPreset={startCompCustomPreset}
                                  setStartCustomPreset={setStartCompCustomPreset}
                                  endCustomPreset={endCompCustomPreset}
                                  setEndCustomPreset={setEndCompCustomPreset}
                                  disabled={selectedCompPerType !== "Custom"}
                                  rangeError={compRangeErrorPreset}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}
              <div className="DPfooter">
                <Button
                  type={ButtonType.OUTLINED}
                  design="secondary"
                  onClick={() => setShow(false)}
                >
                  Cancel
                </Button>
                <Button type={ButtonType.FILLED} onClick={() => setShow(false, true)}>
                  Apply
                </Button>
              </div>
            </div>
          </div>,
          document.body
        )}
    </>
  );
};
