import { QuestionCardProps } from "../../Components/QuestionCard/QuestionCard";

export const questions = [
  {
    "questionNumber": "6A1",
    "title": "Do you have dev resources (in-house or partner) available to assist with platform API implementations?",
    "subtitle": "If you don't have dev resources, Tinuiti can supply them free of charge.",
    "type": "radio",
    "options": ["Yes", "No"],
  },
  {
    "questionNumber": "6A2",
    "title": "If using Google platforms, which site tags track the source of truth for bidding?",
    "type": "radio",
    "options": ["Floodlights", "Google Ads conversion tags", "GA4 Event tags", "N/A"],
  },
  {
    "questionNumber": "6A3",
    "title": "Please list all of the conversion actions used to measure performance.",
    "subtitle": "If the main conversion KPI is purchase, please provide any site testing criteria for test purchases.",
    "type": "textarea",
    "textLabel": "List conversion actions here:",
  },
  {
    "questionNumber": "6A4",
    "title": "What is your company's marketing objective?",
    "subtitle": "If not, Tinuiti has resources available free of charge.",
    "type": "checkbox",
    "options": ["Drive Awareness", "Generate Leads", "Online Sales", "App Downloads", "Other"],
    "nestedOptionsConfig": {
      "Other": {
        "inputType": "textarea",
        "placeholder": "List here"
      }
    },
  },
  {
    "questionNumber": "6A5",
    "title": "Do you collect first party user data on your website's order confirmation pages?",
    "type": "radio",
    "options": ["Yes", "No"],
    "nestedOptionsConfig": {
      "Yes": {
        "inputType": "checkbox",
        "options": ["Conversions API for Offline", "OCAPI", "2nd Party Partner", "Manual Uploads"]
      }
    },
  },
  {
    "questionNumber": "6A6",
    "title": "Are you working with partner(s) to ingest offline customer data?",
    "type": "radio",
    "options": ["Yes", "No"],
    "nestedOptionsConfig": {
      "Yes": {
        "inputType": "textarea",
        "label": "Partner Name",
        "placeholder": "Example Text"
      }
    },
  },
  {
    "questionNumber": "6A7",
    "title": "Do you have additional data sources?",
    "type": "radio",
    "options": ["Yes", "No"],
    "nestedOptionsConfig": {
      "Yes": {
        "inputType": "checkbox",
        "options": ["In-store sales purchase data", "Customer data submitted on website", "Loyalty program / membership", "Surveys", "Call center data", "Other"]
      }
    },
  },
  {
    "questionNumber": "6A8",
    "title": "Do you have any difficulties or restrictions passing first party data into platforms such as Meta?",
    "subtitle": "Ex: technical limitations, messy data",
    "type": "radio",
    "options": ["Yes", "No"],
  }
] as QuestionCardProps[];
