import React from "react";
import OnboardingFormPage from "../OnboardingFormPage";

enum BudgetsBillingTabs {
  BUDGET_STRATEGY = "budget-strategy",
  BUDGET_DETAILS = "budget-details",
}

const renderTabs = [
  { label: "Budget Strategy", headerLabel: "A. Budget Strategy", key: BudgetsBillingTabs.BUDGET_STRATEGY, percentage: 100, children: <div>TODO: Budget Strategy</div> },
  { label: "Budget Details", headerLabel: "B. Budget Details", key: BudgetsBillingTabs.BUDGET_DETAILS, percentage: 0, children: <div>TODO: Budget Details</div> },
];


const BudgetsBilling: React.FC = () => {
  return (
    <OnboardingFormPage
      renderTabs={renderTabs}
      defaultActiveTab={BudgetsBillingTabs.BUDGET_STRATEGY}
      tabs={[BudgetsBillingTabs.BUDGET_STRATEGY, BudgetsBillingTabs.BUDGET_DETAILS]}
    />
  );
};

export default BudgetsBilling;
