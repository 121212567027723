import React, { useState, useEffect, useRef } from "react";
import { Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { MdOutlineInsertLink } from "react-icons/md";
import { Button, ButtonType } from "../Button/Button";
import "./QuestionCard.scss";
import { Response, Selection } from "@blisspointmedia/bpm-types/dist/Onboarding";

interface NestedOptionConfig {
  inputType: "textarea" | "checkbox";
  options?: string[];
  label?: string;
  placeholder?: string;
}

export interface QuestionCardProps {
  questionNumber: string;
  title: string;
  subtitle?: string;
  url?: string;
  children?: React.ReactNode;
  type?: "radio" | "checkbox" | "textarea" | "upload" | "custom";
  textLabel?: string;
  options?: string[];
  onChange?: (updatedResponse: Response) => void;
  initialValue?: Response;
  readOnly?: boolean;
  className?: string;
  placeholder?: string;
  nestedOptionsConfig?: { [option: string]: NestedOptionConfig };
}

const QuestionCard = ({
  questionNumber,
  title,
  subtitle,
  children,
  type,
  textLabel,
  options,
  initialValue,
  readOnly = false,
  className,
  url = window.location.href.split("#")[0],
  placeholder = "What else should we know?",
  nestedOptionsConfig = {},
  onChange,
}: QuestionCardProps): JSX.Element => {
  const [response, setResponse] = useState<Response>(
    initialValue || { questionNumber, selections: [], additionalInfo: "" }
  );
  const [nestedValues, setNestedValues] = useState<{ [option: string]: string }>({});
  const [focused, setFocused] = useState<boolean>(false);
  const questionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (initialValue) {
      setResponse(initialValue);
    }
    setNestedValues({});
  }, [initialValue]);

  useEffect(() => {
    const handleHashChange = () => {
      if (window.location.hash === `#${questionNumber}`) {
        setFocused(true);
        questionRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        setFocused(false);
      }
    };

    handleHashChange();

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [questionNumber]);

  const changeArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const updatedResponse = { ...response, additionalInfo: e.target.value };
    setResponse(updatedResponse);
    if (onChange) {
      onChange(updatedResponse);
    }
  };

  const handleRadioChange = (label: string) => {
    const newSelection: Selection = { label };
    const updatedResponse = { ...response, selections: [newSelection] };
    setResponse(updatedResponse);
    if (onChange) {
      onChange(updatedResponse);
    }
  };

  const handleCheckboxChange = (label: string) => {
    const isSelected = response.selections.some((selection) => selection.label === label);
    const newSelections = isSelected
      ? response.selections.filter((selection) => selection.label !== label)
      : [...response.selections, { label }];

    const updatedResponse = { ...response, selections: newSelections };
    setResponse(updatedResponse);
    if (onChange) {
      onChange(updatedResponse);
    }
  };

  const handleNestedChange = (
    parentLabel: string,
    nestedLabel: string,
    value: string,
    isCheckbox?: boolean
  ) => {
    const updatedSelections = response.selections.map((selection) => {
      if (selection.label === parentLabel) {
        let updatedChildren: Selection[];

        if (isCheckbox) {
          const childExists = selection.children?.some((child) => child.label === nestedLabel);
          if (childExists) {
            updatedChildren = selection.children!.filter((child) => child.label !== nestedLabel);
          } else {
            updatedChildren = [...(selection.children || []), { label: nestedLabel }];
          }
        } else {
          // Handle nested textarea changes
          updatedChildren = selection.children
            ? selection.children.map((child) =>
                child.label === nestedLabel ? { ...child, openText: value } : child
              )
            : [{ label: nestedLabel, openText: value }];
        }

        return { ...selection, children: updatedChildren };
      }
      return selection;
    });

    const updatedResponse = { ...response, selections: updatedSelections };
    setResponse(updatedResponse);

    if (onChange) {
      onChange(updatedResponse);
    }
  };

  const handleUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      const newSelection: Selection = { label: file.name };
      const updatedResponse = { ...response, selections: [...response.selections, newSelection] };
      setResponse(updatedResponse);
      if (onChange) {
        onChange(updatedResponse);
      }
    }
  };

  const renderNestedInput = (option: string, isParentSelected: boolean) => {
    if (nestedOptionsConfig[option]) {
      const config = nestedOptionsConfig[option];
      const defaultPlaceholder = "Enter text here...";

      return (
        <>
          {config.label && (
            <Form.Label htmlFor={`${questionNumber}-${option}-nested`}>{config.label}</Form.Label>
          )}
          {config.inputType === "textarea" && (
            <Form.Group controlId={`${questionNumber}-${option}-textarea`}>
              <Form.Control
                as="textarea"
                value={nestedValues[option] || ""}
                onChange={(e) => {
                  const { value } = e.target;
                  setNestedValues((prevValues) => ({ ...prevValues, [option]: value }));
                  handleNestedChange(option, config.label || option, value);
                }}
                placeholder={config.placeholder || defaultPlaceholder}
                disabled={readOnly || !isParentSelected}
              />
            </Form.Group>
          )}
          {config.inputType === "checkbox" &&
            config.options?.map((opt, index) => {
              const isSelected = response.selections.some(
                (selection) =>
                  selection.label === option && selection.children?.some((child) => child.label === opt)
              );
              return (
                <Form.Check
                  key={index}
                  type="checkbox"
                  name={`${questionNumber}-${opt}-${index}`}
                  id={`${questionNumber}-${opt}-${index}-checkbox`}
                  label={opt}
                  checked={isSelected}
                  onChange={() => handleNestedChange(option, opt, "", true)}
                  disabled={readOnly || !isParentSelected}
                />
              );
            })}
        </>
      );
    }
    return null;
  };

  const renderOptions = (
    options: string[],
    questionNumber: string,
    type: "radio" | "checkbox",
    handleChange: (val: string) => void
  ) => {
    return options.map((option, index) => {
      const isSelected = type === "checkbox"
        ? response.selections.some((selection) => selection.label === option)
        : response.selections.length > 0 && response.selections[0].label === option;

      return (
        <div key={index}>
          <Form.Check
            type={type}
            name={questionNumber}
            id={`${questionNumber}-${option}-${type}`}
            label={option}
            checked={isSelected}
            onChange={() => {
              if (type === "checkbox") {
                handleCheckboxChange(option);
              } else {
                handleRadioChange(option);
              }
            }}
            disabled={readOnly}
          />
          {renderNestedInput(option, isSelected)}
        </div>
      );
    });
  };

  const renderUpload = () => (
    <Form.Group>
      <Form.Label htmlFor={`${questionNumber}-upload`}>Upload</Form.Label>
      <Form.Control
        type="file"
        id={`${questionNumber}-upload`}
        onChange={handleUploadChange}
        disabled={readOnly}
      />
      {renderAdditionalInfo()}
    </Form.Group>
  );

  const renderCustom = () => (
    <Form.Group>
      {children}
      <Form.Label htmlFor={`${questionNumber}-custom-additionalInfo`}>Additional Info:</Form.Label>
      <Form.Control
        id={`${questionNumber}-custom-additionalInfo`}
        value={response.additionalInfo || ""}
        onChange={changeArea}
        as="textarea"
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </Form.Group>
  );

  const renderTextarea = () => (
    <Form.Group>
      <Form.Label htmlFor={`${questionNumber}-textarea`}>{textLabel}</Form.Label>
      <Form.Control
        id={`${questionNumber}-textarea`}
        value={response.additionalInfo || ""}
        onChange={changeArea}
        as="textarea"
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </Form.Group>
  );

  const renderAdditionalInfo = () => (
    <Form.Group>
      <Form.Label htmlFor={`${questionNumber}-additionalInfo`}>Additional Info:</Form.Label>
      <Form.Control
        id={`${questionNumber}-additionalInfo`}
        value={response.additionalInfo || ""}
        onChange={changeArea}
        as="textarea"
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </Form.Group>
  );

  let formElement;
  switch (type) {
    case "radio":
      formElement = (
        <>
          {renderOptions(options || [], questionNumber, "radio", handleRadioChange)}
          {renderAdditionalInfo()}
        </>
      );
      break;
    case "checkbox":
      formElement = (
        <>
          {renderOptions(options || [], questionNumber, "checkbox", handleCheckboxChange)}
          {renderAdditionalInfo()}
        </>
      );
      break;
    case "textarea":
      formElement = renderTextarea();
      break;
    case "upload":
      formElement = renderUpload();
      break;
    case "custom":
      formElement = renderCustom();
      break;
    default:
      formElement = null;
  }

  return (
    <div id={questionNumber} className={`questionCard ${focused ? "focused" : ""} ${className || ""}`} ref={questionRef}>
      <div className="questionHeader">
        <p className="questionNumber">{questionNumber}</p>
        <OverlayTrigger overlay={<Tooltip id={`${questionNumber}-copyButton`}>Copy</Tooltip>}>
          <Button
            id={`${questionNumber}-copyButton`}
            type={ButtonType.EMPTY}
            className="onboardingButton"
            onClick={() => {
              navigator.clipboard.writeText(`${url}#${questionNumber}`);
            }}
            icon={<MdOutlineInsertLink />}
          />
        </OverlayTrigger>
      </div>
      <h2>{title}</h2>
      <p>{subtitle}</p>
      {formElement}
    </div>
  );
};

export default QuestionCard;
