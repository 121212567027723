import "./SegmentationMapping.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTabbedNav } from "../utils/hooks/useNav";
import { Page } from "../Components";
import SegmentationIdCreation from "./SegmentationIdCreation";
import SegmentationLabeling, { SegmentRow } from "./SegmentationLabeling";
import CustomSegments from "./CustomSegments";
import { RouteComponentProps, Router } from "@reach/router";
import {
  MiscLambdaFetch,
  SegmentationMappingLambdaFetch,
  awaitJSON,
  pollS3,
} from "../utils/fetch-utils";
import { useCompanyInfo } from "../redux/company";
import { useSetError } from "../redux/modals";

const enum PageTab {
  ID_GENERATION = "id-generation",
  LABELING = "labeling",
  CUSTOM_SEGMENTS = "custom-segments",
}

const NAVS = [
  { label: "ID Generation", key: PageTab.ID_GENERATION },
  { label: "Labeling", key: PageTab.LABELING },
  { label: "Custom Segments", key: PageTab.CUSTOM_SEGMENTS },
];

export interface CustomSegmentsData {
  segmentId: number;
  segmentName: string;
  values: { valueId: number; valueName: string }[];
}

const SegmentationMapping = React.memo(({ navigate }: RouteComponentProps) => {
  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "segmentation-mapping",
    defaultKey: PageTab.ID_GENERATION,
  });

  const { cid } = useCompanyInfo();
  const setError = useSetError();

  const [rawData, setRawData] = useState<SegmentRow[] | null>(null);

  const fetchRawData = useCallback(async () => {
    try {
      const lambdaArgs = { company: cid };
      const result = await MiscLambdaFetch("/kickOffLambda", {
        method: "POST",
        body: {
          fileType: "txt",
          lambdaArgs,
          lambdaName: "segmentationmapping-getRawPlatformData",
        },
      });
      const uuid = await awaitJSON(result);
      const content = await pollS3({
        autoDownload: false,
        bucket: "bpm-cache",
        filename: `${uuid}.txt`,
        mimeType: "text/plain",
      });
      const textContent = await content.text();
      const lambdaResult = JSON.parse(textContent);

      if (lambdaResult.cometErrorMessage) {
        setError({ message: lambdaResult.cometErrorMessage });
      } else {
        setRawData(lambdaResult.data as SegmentRow[]);
      }
    } catch (e) {
      const reportError = e as Error;
      setError({ message: reportError.message, reportError });
    }
  }, [cid, setError]);

  // Fetch existing data at the main page level so that you don't need to wait for the load when you switch tabs.
  useEffect(() => {
    if (!rawData) {
      fetchRawData();
    }
  }, [cid, setError, rawData, fetchRawData]);

  const [customSegments, setCustomSegments] = useState<CustomSegmentsData[] | undefined>();

  // Get existing custom segments
  useEffect(() => {
    if (!customSegments) {
      (async () => {
        try {
          let res = await SegmentationMappingLambdaFetch("/getCustomSegments", {
            params: { company: cid },
          });
          const data = await awaitJSON(res);
          setCustomSegments(data);
        } catch (e) {
          const reportError = e as Error;
          setError({ message: reportError.message, reportError });
        }
      })();
    }
  }, [cid, customSegments, setError]);

  const dataGranularity: "campaign" | "ad_group" | "ad" = useMemo(() => {
    if (!rawData || rawData.length < 1) {
      return "campaign";
    }

    if (rawData[0].ad_id) {
      return "ad";
    }

    if (rawData[0].ad_group_id) {
      return "ad_group";
    }

    return "campaign";
  }, [rawData]);

  return (
    <Page
      app2Redesign
      pageType="Segmentation Mapping"
      title="Segmentation Mapping"
      navs={NAVS}
      selectedNav={tab}
      onNav={goToTab}
    >
      <div className="segmentationMappingPage">
        <Router className="fullPageRouter">
          <SegmentationIdCreation
            default
            path={PageTab.ID_GENERATION}
            customSegments={customSegments}
            setCustomSegments={setCustomSegments}
          />
          <SegmentationLabeling
            path={PageTab.LABELING}
            data={rawData}
            fetchRawData={fetchRawData}
            customSegments={customSegments}
            dataGranularity={dataGranularity}
          />
          <CustomSegments
            data={customSegments}
            setData={setCustomSegments}
            path={PageTab.CUSTOM_SEGMENTS}
          />
        </Router>
      </div>
    </Page>
  );
});

export default SegmentationMapping;
