import React, { useState, useEffect } from "react";
import { Response } from "@blisspointmedia/bpm-types/dist/Onboarding";
import QuestionCard from "../../Components/QuestionCard/QuestionCard";
import { questions } from "./DataQuestions";
import "./Data.scss";

interface DataMeasurementProps {
  responses: Response[];
  onChange: (updatedResponses: Response[]) => void;
}

const DataMeasurement: React.FC<DataMeasurementProps> = ({ responses, onChange }) => {
  const [currentResponses, setCurrentResponses] = useState<Response[]>(responses || []);

  useEffect(() => {
    setCurrentResponses(responses);
  }, [responses]);

  const handleChange = (updatedResponse: Response) => {
    const existingIndex = currentResponses.findIndex(
      (response) => response.questionNumber === updatedResponse.questionNumber
    );

    let updatedResponses: Response[];

    if (existingIndex !== -1) {
      updatedResponses = currentResponses.map((response, index) =>
        index === existingIndex ? updatedResponse : response
      );
    } else {
      updatedResponses = [...currentResponses, updatedResponse];
    }

    setCurrentResponses(updatedResponses);
    onChange(updatedResponses);
  };

  return (
    <div className="dataQuestions">
      {questions.map((question) => (
        <QuestionCard
          key={question.questionNumber}
          title={question.title}
          subtitle={question.subtitle}
          questionNumber={question.questionNumber}
          type={question.type}
          textLabel={question.textLabel}
          options={question.options}
          initialValue={
            currentResponses.find((response) => response.questionNumber === question.questionNumber) || {
              questionNumber: question.questionNumber,
              selections: [],
              additionalInfo: "",
            }
          }
          nestedOptionsConfig={question.nestedOptionsConfig}
          onChange={(updatedValue) => handleChange(updatedValue)}
        />
      ))}
    </div>
  );
};

export default DataMeasurement;
