import React from "react";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Cell } from "recharts";
import { Brand100 } from "../utils/colors";
import "./PacingBar.scss";
import { Img } from "../Components";
import { isTotalBar } from "./pacingUtils";

const formatYValue = (value, marginLeft, numberOfSegments) => {
  return marginLeft === 1 && numberOfSegments.length > 1 ? `${value.trim()} Total` : value.trim();
};

const CustomYAxisTick = ({
  x,
  y,
  payload,
  marginLeft,
  isModal,
  numberOfSegments,
  longestText,
  total,
  currentSegment,
}) => {
  if (isModal) {
    return (
      <foreignObject x={x - 50} y={y - 10} width={200} height={100}>
        <div className="pacingBarText">Total</div>
      </foreignObject>
    );
  }

  const newValue = formatYValue(payload.value, marginLeft, numberOfSegments);
  const segmentsWithLogos = ["Channel", "Platform"];
  return (
    <foreignObject
      x={x - longestText - 40}
      y={marginLeft === 1 ? y - 15 : y - 10}
      width={200}
      height={125}
    >
      <div className="pacingBarYAxisContainer">
        {segmentsWithLogos.includes(currentSegment) &&
          !total &&
          !(marginLeft === 1 && numberOfSegments.length > 1) && (
            <div className="pacingBarLogos">
              {" "}
              <Img
                src={`https://cdn.blisspointmedia.com/assets/img/${`${currentSegment.toLowerCase()}s`}/${payload.value.replace(
                  /\s/g,
                  ""
                )}.png`}
                alt="brand health header"
                className="logoImage"
              />
            </div>
          )}
        <div className={`pacingBarText${marginLeft}`}>
          <div>{total ? "Total" : newValue}</div>
        </div>
      </div>
    </foreignObject>
  );
};

const bluesReversed = ["#D9F9FD", "#AEF3FC", "#2AE0F6"];

const getColor = (colors: string[], index: number) => {
  return colors[index];
};

let ctx;

export const measureText: any = text => {
  if (!ctx) {
    ctx = document.createElement("canvas").getContext("2d");
    ctx.font = "1rem 'Graphik";
  }

  return ctx.measureText(text).width;
};

interface PacingBarInfo {
  totalCleared: number;
  totalProjected: number;
  totalRemainder: number;
  segmentName: string;
  budget: number;
}

interface PacingBarProps {
  pacingData: PacingBarInfo;
  keys: string[];
  className?: string;
  colors?: string[];
  marginLeft: number;
  numberOfSegments: string[];
  longestText: number;
  isModal?: boolean;
  total?: boolean;
  currentSegment?: string;
}

const CustomBarWithTarget: any = ({ props, index, targetValue, isModal, marginLeft, keys }) => {
  let { fill, x, y, width, height } = props;
  const budgetText = `$${(targetValue / 1000).toLocaleString("en-us", {
    maximumFractionDigits: 2,
  })}k Budget`;

  if (width < 0) {
    width = 1;
  }

  let fontWeight = 600;
  let fontSize = 14;
  if (marginLeft >= 3) {
    fontWeight = 400;
    fontSize = 12;
  }
  return (
    <svg>
      <rect x={x} y={y} width={width} height={height} stroke="none" fill={fill} />
      {index === 0 && (
        <>
          <line
            x1={x + width}
            x2={x + width}
            y1={y}
            y2={y + height}
            stroke="#007F8E"
            strokeWidth={3}
          />
          <text
            x={x + width + 3}
            y={y + height / 2}
            fill="#007F8E"
            dominantBaseline="middle"
            fontWeight={fontWeight}
            fontSize={fontSize}
          >
            {budgetText}
          </text>
        </>
      )}
    </svg>
  );
};

const renderCustomizedLabel: any = (
  props,
  marginLeft,
  isModal,
  goalValue,
  key,
  pacingData,
  total
) => {
  let valueTag = "";
  let value = 0;
  const { x, y } = props;
  let yHeight = y;
  if (key === "totalRemainder") {
    return null;
  } else if (key === "totalProjected") {
    valueTag = " Projected";
    value = pacingData.totalProjected;
    yHeight = total ? y + 74 : y + 50 - marginLeft * 3.5;
  } else if (key === "totalCleared") {
    valueTag = " Delivered";
    value = pacingData.totalCleared;
    yHeight = y + 5;
  }

  let formattedValue: string;
  if (isModal) {
    formattedValue = `$${(value / 1000).toLocaleString("en-us", {
      maximumFractionDigits: 0,
    })}k/${((value / goalValue) * 100).toFixed(0)}% ${valueTag}`;
  } else {
    formattedValue = `$${(value / 1000).toLocaleString("en-us", {
      maximumFractionDigits: 0,
    })}k ${valueTag}`;
  }

  let fontWeight = 500;
  let fontSize = 14;
  let dominantBaseline = "hanging";
  if (marginLeft === 3) {
    fontWeight = 400;
    fontSize = 12;
    yHeight = key === "totalCleared" ? y + 5 : y + 25;
  } else if (marginLeft === 4) {
    fontWeight = 400;
    fontSize = 12;
    dominantBaseline = "auto";
    yHeight = key === "totalCleared" ? y - 2 : y + 27;
  }

  return (
    <text
      x={x + 9.92}
      y={yHeight}
      fill={Brand100}
      textAnchor="start"
      dominantBaseline={dominantBaseline}
      fontSize={fontSize}
      fontFamily="Graphik"
      fontWeight={fontWeight}
    >
      {formattedValue}
    </text>
  );
};

const PacingBar: React.FC<PacingBarProps> = ({
  pacingData,
  keys,
  colors = bluesReversed,
  className,
  marginLeft,
  numberOfSegments,
  longestText,
  isModal = false,
  total = false,
  currentSegment,
}) => {
  const maxTextWidth = measureText(pacingData.segmentName);
  let containerHeight = isTotalBar(total, marginLeft, numberOfSegments) ? 122 : 92;
  let barHeight = isTotalBar(total, marginLeft, numberOfSegments) ? 90 : 60;
  if (isModal) {
    containerHeight = 92;
    barHeight = 60;
  }
  if (marginLeft === 3) {
    containerHeight = 72;
    barHeight = 36;
  } else if (marginLeft === 4) {
    containerHeight = 48;
    barHeight = 16;
  }

  const barWidth = 100 - marginLeft * 10;
  return (
    <ResponsiveContainer width={`${barWidth}%`} height={containerHeight} debounce={50}>
      <BarChart
        reverseStackOrder={true}
        data={[pacingData]}
        layout="vertical"
        margin={{
          left: isModal ? 35 : longestText,
          right: maxTextWidth + 30,
        }}
      >
        <XAxis hide type="number" />
        <YAxis
          dataKey={"segmentName"}
          type="category"
          axisLine={false}
          tickLine={false}
          tick={props => (
            <CustomYAxisTick
              {...props}
              marginLeft={marginLeft}
              isModal={isModal}
              numberOfSegments={numberOfSegments}
              longestText={longestText}
              total={total}
              currentSegment={currentSegment}
            />
          )}
          tickFormatter={value => formatYValue(value, marginLeft, numberOfSegments)}
        />
        {keys
          .slice()
          .reverse()
          .map((key, index) => (
            <Bar
              key={key}
              dataKey={key}
              stackId="a"
              barSize={barHeight}
              label={props =>
                renderCustomizedLabel(
                  props,
                  marginLeft,
                  isModal,
                  pacingData.budget,
                  key,
                  pacingData,
                  isTotalBar(total, marginLeft, numberOfSegments)
                )
              }
              shape={props => (
                <CustomBarWithTarget
                  props={props}
                  index={index}
                  targetValue={pacingData.budget}
                  isModal={isModal}
                  marginLeft={marginLeft}
                  keys={keys}
                />
              )}
            >
              {[pacingData].map((entry, idx) => (
                <Cell key={`cell-${idx}`} fill={getColor(colors, index)} />
              ))}
            </Bar>
          ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default PacingBar;
