import { ClientInfo, ClientInfoParams } from "@blisspointmedia/bpm-types/dist/Onboarding";
import { ToolsLambdaFetch, awaitJSON } from "../utils/fetch-utils";

export const getClientInfo = async (company: string): Promise<ClientInfo> => {
  let res = await ToolsLambdaFetch("/getClientInfo", {
    params: {
      company,
    },
  });
  return await awaitJSON(res);
};

export const saveClientInfo = async (clientInfoParams: ClientInfoParams): Promise<{statusCode: number, body: string}> => {
  let res = await ToolsLambdaFetch("/createClientInfo", {
    method: "POST",
    body: JSON.stringify(clientInfoParams),
  });
  return await awaitJSON(res);
};
