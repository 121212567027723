import "./CustomSegments.scss";
import * as R from "ramda";
import * as uuid from "uuid";
import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Form } from "react-bootstrap";
import { MdSave, MdCancel, MdAdd, MdEdit } from "react-icons/md";
import { BPMButton, FullPageSpinner, Spinner } from "../Components";
import { useCompanyInfo } from "../redux/company";
import { useSetError } from "../redux/modals";
import { SegmentationMappingLambdaFetch } from "../utils/fetch-utils";
import { RouteComponentProps } from "@reach/router";
import { CustomSegmentsData } from "./SegmentationMapping";
import { StateSetter } from "../utils/types";
import { useMap } from "../utils/hooks/useData";

interface NewValue {
  name: string;
  segmentId: number | string;
  existingSegment?: boolean;
}

interface CombinedNewData {
  [segmentId: string]: {
    name: string;
    values: string[];
  };
}

const TACTIC_LOCKED_VALUES = ["prospecting", "retargeting", "winback", "retention"];
const BRAND_VS_NONBRAND_LOCKED_VALUES = ["brand", "nonbrand", "conquesting", "dsa", "blended"];
const FUNNEL_TIER_LOCKED_VALUES = ["upper funnel", "mid funnel", "lower funnel"];
const PROMO_LOCKED_VALUES = ["evergreen", "sale"];
const OBJECTIVE_LOCKED_VALUES = [
  "conversions",
  "sales",
  "traffic",
  "awareness",
  "leads",
  "video Views",
  "reach",
];

enum segmentOptions {
  TACTIC = "Tactic",
  BRAND_VS_NONBRAND = "Brand vs Nonbrand",
  FUNNEL_TIER = "Funnel Tier",
  PROMO = "Promo",
  OBJECTIVE = "Objective",
  CUSTOM = "",
}

const CustomSegments = ({
  data,
  setData,
}: {
  data: CustomSegmentsData[] | undefined;
  setData: StateSetter<CustomSegmentsData[] | undefined>;
} & RouteComponentProps): JSX.Element => {
  const setError = useSetError();
  const { cid } = useCompanyInfo();
  const [newSegments, setNewSegments] = useState<Record<string, string>>({});
  const [newSegmentValues, setNewSegmentValues] = useState<Record<string, NewValue>>({});
  const [editedSegmentNamesMap, setEditedSegmentName, setEditedSegmentNameMap] = useMap<
    string,
    string | undefined
  >({});
  const [
    editedSegmentValueNamesMap,
    setEditedSegmentValueName,
    setEditedSegmentValueNameMap,
  ] = useMap<string, string | undefined>({});
  const [segmentsInEditModeMap, setSegmentsInEditModeValue, setSegmentsInEditModeMap] = useMap<
    string,
    boolean
  >({});
  const [segments, setSegments] = useState<string[]>([]);
  const [saving, setSaving] = useState(false);

  const [unEditableSegmentIdsAll, setUnEditableSegmentIdsAll] = useState<number[]>([]);
  const [currentSegmentList, setCurrentSegmentList] = useState<string[]>([]);
  const UNEDITABLE_SEGMENT_IDS = [1, 2, 3, 4]; // Channel, Platform, Campaign Ownership and Include in Fee Calc values should not be editable

  useEffect(() => {
    if (data) {
      const unEditableIds = data
        .filter(
          segment =>
            segment.segmentName === "Platform" ||
            segment.segmentName === "Channel" ||
            segment.segmentName === "Campaign Ownership" ||
            segment.segmentName === "Include in Fee Calc" ||
            segment.segmentName === segmentOptions.TACTIC ||
            segment.segmentName === segmentOptions.BRAND_VS_NONBRAND ||
            segment.segmentName === segmentOptions.FUNNEL_TIER ||
            segment.segmentName === segmentOptions.PROMO ||
            segment.segmentName === segmentOptions.OBJECTIVE
        )
        .map(segment => segment.segmentId);
      const currentSegments = data.map(segment => segment.segmentName);
      setCurrentSegmentList(currentSegments);
      setUnEditableSegmentIdsAll(unEditableIds);
    }
  }, [setError, cid, data]);

  const checkIfLockedValue = useCallback(
    (value: string, segmentName: string) => {
      if (
        TACTIC_LOCKED_VALUES.includes(value.toLocaleLowerCase()) &&
        segmentName !== segmentOptions.TACTIC
      ) {
        setError({
          message: `Please add the preset ${segmentOptions.TACTIC} segment to label campaigns with "${value}".`,
        });
      }
      if (
        BRAND_VS_NONBRAND_LOCKED_VALUES.includes(value.toLocaleLowerCase()) &&
        segmentName !== segmentOptions.BRAND_VS_NONBRAND
      ) {
        setError({
          message: `Please add the preset ${segmentOptions.BRAND_VS_NONBRAND} segment to label campaigns with "${value}".`,
        });
      }
      if (
        FUNNEL_TIER_LOCKED_VALUES.includes(value.toLocaleLowerCase()) &&
        segmentName !== segmentOptions.FUNNEL_TIER
      ) {
        setError({
          message: `Please add the preset ${segmentOptions.FUNNEL_TIER} segment to label campaigns with "${value}".`,
        });
      }
      if (
        PROMO_LOCKED_VALUES.includes(value.toLocaleLowerCase()) &&
        segmentName !== segmentOptions.PROMO
      ) {
        setError({
          message: `Please add the preset ${segmentOptions.PROMO} segment to label campaigns with "${value}".`,
        });
      }
      if (
        OBJECTIVE_LOCKED_VALUES.includes(value.toLocaleLowerCase()) &&
        segmentName !== segmentOptions.OBJECTIVE
      ) {
        setError({
          message: `Please add the preset ${segmentOptions.OBJECTIVE} segment to label campaigns with "${value}".`,
        });
      }
    },
    [setError]
  );

  // Adds a new custom segment
  const addNewSegment = useCallback((option: segmentOptions, index: number) => {
    const segmentNameId = uuid.v4();
    const segmentValueId = uuid.v4();
    switch (option) {
      case segmentOptions.TACTIC:
        setSegments(current => {
          const newSegments = [...current];
          newSegments[index] = segmentNameId;
          return newSegments;
        });
        setNewSegments(current => ({
          ...current,
          [segmentNameId]: segmentOptions.TACTIC,
        }));
        setNewSegmentValues(current => ({
          ...current,
          [uuid.v4()]: {
            name: "Prospecting",
            segmentId: segmentNameId,
            existingSegment: false,
          },
          [uuid.v4()]: { name: "Retargeting", segmentId: segmentNameId, existingSegment: false },
          [uuid.v4()]: { name: "Winback", segmentId: segmentNameId, existingSegment: false },
          [uuid.v4()]: { name: "Retention", segmentId: segmentNameId, existingSegment: false },
          [uuid.v4()]: { name: "N/A", segmentId: segmentNameId, existingSegment: false },
        }));
        break;
      case segmentOptions.BRAND_VS_NONBRAND:
        setSegments(current => {
          const newSegments = [...current];
          newSegments[index] = segmentNameId;
          return newSegments;
        });
        setNewSegments(current => ({
          ...current,
          [segmentNameId]: segmentOptions.BRAND_VS_NONBRAND,
        }));
        setNewSegmentValues(current => ({
          ...current,
          [uuid.v4()]: { name: "Brand", segmentId: segmentNameId, existingSegment: false },
          [uuid.v4()]: { name: "Nonbrand", segmentId: segmentNameId, existingSegment: false },
          [uuid.v4()]: { name: "Conquesting", segmentId: segmentNameId, existingSegment: false },
          [uuid.v4()]: { name: "DSA", segmentId: segmentNameId, existingSegment: false },
          [uuid.v4()]: { name: "Blended", segmentId: segmentNameId, existingSegment: false },
          [uuid.v4()]: { name: "N/A", segmentId: segmentNameId, existingSegment: false },
        }));
        break;
      case segmentOptions.FUNNEL_TIER:
        setSegments(current => {
          const newSegments = [...current];
          newSegments[index] = segmentNameId;
          return newSegments;
        });
        setNewSegments(current => ({
          ...current,
          [segmentNameId]: segmentOptions.FUNNEL_TIER,
        }));
        setNewSegmentValues(current => ({
          ...current,
          [uuid.v4()]: { name: "Upper Funnel", segmentId: segmentNameId, existingSegment: false },
          [uuid.v4()]: { name: "Mid Funnel", segmentId: segmentNameId, existingSegment: false },
          [uuid.v4()]: { name: "Lower Funnel", segmentId: segmentNameId, existingSegment: false },
          [uuid.v4()]: { name: "N/A", segmentId: segmentNameId, existingSegment: false },
        }));
        break;
      case segmentOptions.PROMO:
        setSegments(current => {
          const newSegments = [...current];
          newSegments[index] = segmentNameId;
          return newSegments;
        });
        setNewSegments(current => ({
          ...current,
          [segmentNameId]: segmentOptions.PROMO,
        }));
        setNewSegmentValues(current => ({
          ...current,
          [uuid.v4()]: { name: "Evergreen", segmentId: segmentNameId, existingSegment: false },
          [uuid.v4()]: { name: "Sale", segmentId: segmentNameId, existingSegment: false },
          [uuid.v4()]: { name: "N/A", segmentId: segmentNameId, existingSegment: false },
        }));
        break;
      case segmentOptions.OBJECTIVE:
        setSegments(current => {
          const newSegments = [...current];
          newSegments[index] = segmentNameId;
          return newSegments;
        });
        setNewSegments(current => ({
          ...current,
          [segmentNameId]: segmentOptions.OBJECTIVE,
        }));
        setNewSegmentValues(current => ({
          ...current,
          [uuid.v4()]: { name: "Conversions", segmentId: segmentNameId, existingSegment: false },
          [uuid.v4()]: { name: "Sales", segmentId: segmentNameId, existingSegment: false },
          [uuid.v4()]: { name: "Traffic", segmentId: segmentNameId, existingSegment: false },
          [uuid.v4()]: { name: "Awareness", segmentId: segmentNameId, existingSegment: false },
          [uuid.v4()]: { name: "Leads", segmentId: segmentNameId, existingSegment: false },
          [uuid.v4()]: { name: "Video Views", segmentId: segmentNameId, existingSegment: false },
          [uuid.v4()]: { name: "Reach", segmentId: segmentNameId, existingSegment: false },
          [uuid.v4()]: { name: "N/A", segmentId: segmentNameId, existingSegment: false },
        }));
        break;
      case segmentOptions.CUSTOM:
        setSegments(current => {
          const newSegments = [...current];
          newSegments[index] = segmentNameId;
          return newSegments;
        });
        setNewSegments(current => ({
          ...current,
          [segmentNameId]: "",
        }));
        setNewSegmentValues(current => ({
          ...current,
          [segmentValueId]: { name: "N/A", segmentId: segmentNameId, existingSegment: false },
        }));
        break;
    }
  }, []);

  // Adds a new value to a segment
  const addNewSegmentValue = useCallback((name, segmentId, existingSegment = false) => {
    const id = uuid.v4();
    setNewSegmentValues(current => ({ ...current, [id]: { name, segmentId, existingSegment } }));
  }, []);

  // Discard all unsaved changes
  const discardChanges = useCallback(() => {
    setSegments([]);
    setNewSegments({});
    setNewSegmentValues({});
    setEditedSegmentNameMap({});
    setEditedSegmentValueNameMap({});
    setSegmentsInEditModeMap({});
  }, [setEditedSegmentNameMap, setEditedSegmentValueNameMap, setSegmentsInEditModeMap]);

  // Save Changes
  const saveChanges = useCallback(async () => {
    try {
      setSaving(true);

      let combinedNewData: CombinedNewData = {};

      for (let [segmentId, segmentName] of Object.entries(newSegments)) {
        combinedNewData[segmentId] = {
          name: segmentName,
          values: [],
        };
      }

      const newValuesForExistingSegments: any[] = [];

      for (let value of Object.values(newSegmentValues)) {
        const { segmentId, name, existingSegment } = value;
        if (existingSegment) {
          newValuesForExistingSegments.push({ name, segmentId });
        } else {
          combinedNewData[segmentId].values.push(name);
        }
      }

      const newSegmentNameToValuesMap = {};
      for (let obj of Object.values(combinedNewData)) {
        newSegmentNameToValuesMap[obj.name] = obj.values;
      }

      let editedSegmentNames = {};
      Object.keys(editedSegmentNamesMap).forEach(key => {
        const value = editedSegmentNamesMap[key];
        if (!!value) {
          editedSegmentNames[key] = value;
        }
      });

      let editedSegmentValueNames = {};
      Object.keys(editedSegmentValueNamesMap).forEach(key => {
        const value = editedSegmentValueNamesMap[key];
        if (!!value) {
          editedSegmentValueNames[key] = value;
        }
      });

      await SegmentationMappingLambdaFetch("/updateCustomSegments", {
        method: "POST",
        body: {
          company: cid,
          newSegments: newSegmentNameToValuesMap,
          newValuesForExistingSegments,
          editedSegmentNames,
          editedSegmentValueNames,
        },
      });

      setData(undefined);
      setSegments([]);
      setNewSegments({});
      setNewSegmentValues({});
      setEditedSegmentNameMap({});
      setEditedSegmentValueNameMap({});
      setSegmentsInEditModeMap({});
      setSaving(false);
    } catch (e) {
      setSaving(false);
      const reportError = e as Error;
      setError({ message: `Failed to save changes: ${reportError.message}`, reportError });
    }
  }, [
    cid,
    editedSegmentNamesMap,
    editedSegmentValueNamesMap,
    newSegmentValues,
    newSegments,
    setData,
    setEditedSegmentNameMap,
    setEditedSegmentValueNameMap,
    setError,
    setSegmentsInEditModeMap,
  ]);

  const hasEdits = useMemo(() => {
    return !(
      R.isEmpty(newSegments) &&
      R.isEmpty(newSegmentValues) &&
      R.isEmpty(Object.values(editedSegmentNamesMap).filter(val => !!val)) &&
      R.isEmpty(Object.values(editedSegmentValueNamesMap).filter(val => !!val))
    );
  }, [editedSegmentNamesMap, editedSegmentValueNamesMap, newSegmentValues, newSegments]);

  const handleSegmentNameOnChange = useCallback(({ segment, value, shouldTrim }) => {
    const newVal = shouldTrim ? value.trim() : value;
    setNewSegments(current => ({
      ...current,
      [segment]: newVal,
    }));
  }, []);

  const handleSegmentValueOnChange = useCallback(
    ({ segment, value, valueId, segmentId, shouldTrim }) => {
      let newVal = shouldTrim ? value.trim() : value;
      checkIfLockedValue(newVal, segment);
      setNewSegmentValues(current => ({
        ...current,
        [valueId]: { name: newVal, segmentId },
      }));
    },
    [checkIfLockedValue]
  );

  return data ? (
    <div className="customSegmentsPage">
      <div className="segmentList">
        {data.map(segmentObj => {
          const { segmentId, segmentName, values } = segmentObj;
          return (
            <div className="segmentContainer" key={segmentId}>
              <div className="segmentHeader">
                {segmentsInEditModeMap[segmentId] && !UNEDITABLE_SEGMENT_IDS.includes(segmentId) ? (
                  <Form.Control
                    size="sm"
                    placeholder="New Value"
                    type="text"
                    value={editedSegmentNamesMap[segmentId] || segmentName}
                    key={segmentId}
                    onChange={e => {
                      checkIfLockedValue(e.currentTarget.value, segmentName);
                      setEditedSegmentName(segmentId.toString(), e.currentTarget.value);
                    }}
                  />
                ) : (
                  <div className="segmentName">{segmentName}</div>
                )}
                {segmentsInEditModeMap[segmentId] && !unEditableSegmentIdsAll.includes(segmentId) && (
                  <BPMButton
                    className="editSegmentButton"
                    size="sm"
                    onClick={() => {
                      setEditedSegmentName(segmentId.toString(), undefined);
                      values.forEach(val =>
                        setEditedSegmentValueName(val.valueId.toString(), undefined)
                      );
                      setSegmentsInEditModeValue(segmentId.toString(), false);
                    }}
                  >
                    <MdCancel />
                  </BPMButton>
                )}
                {!segmentsInEditModeMap[segmentId] && !unEditableSegmentIdsAll.includes(segmentId) && (
                  <BPMButton
                    className="editSegmentButton"
                    size="sm"
                    onClick={() => setSegmentsInEditModeValue(segmentId.toString(), true)}
                  >
                    <MdEdit />
                  </BPMButton>
                )}
              </div>
              <div className="segmentValues">
                {!UNEDITABLE_SEGMENT_IDS.includes(segmentId) && (
                  <BPMButton
                    className="addSegmentValueButton"
                    size="sm"
                    onClick={e => addNewSegmentValue("", segmentId, true)}
                  >
                    <MdAdd />
                  </BPMButton>
                )}
                {Object.entries(newSegmentValues)
                  .filter(([valueId, value]) => value.segmentId === segmentId)
                  .map(([valueId, value]) => {
                    return (
                      <Form.Control
                        size="sm"
                        placeholder="New Value"
                        type="text"
                        value={value.name}
                        key={valueId}
                        onChange={e => {
                          const newVal = e.currentTarget.value;
                          checkIfLockedValue(e.currentTarget.value, segmentName);
                          setNewSegmentValues(current => ({
                            ...current,
                            [valueId]: {
                              name: newVal,
                              segmentId: value.segmentId,
                              existingSegment: true,
                            },
                          }));
                        }}
                      />
                    );
                  })}
                {values.map(value => {
                  const { valueId, valueName } = value;
                  return segmentsInEditModeMap[segmentId] &&
                    !UNEDITABLE_SEGMENT_IDS.includes(segmentId) ? (
                    <Form.Control
                      size="sm"
                      placeholder="New Value"
                      type="text"
                      value={editedSegmentValueNamesMap[valueId] || valueName}
                      key={valueId}
                      onChange={e => {
                        setEditedSegmentValueName(valueId.toString(), e.currentTarget.value);
                      }}
                    />
                  ) : (
                    <div className="option" key={valueId}>
                      {valueName}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        {segments.map((segment, index) =>
          segment === "a" ? (
            <div key={index} className="segmentContainer">
              <div className="segmentHeader">
                <div className="segmentName">Choose Segment type</div>
              </div>
              <div className="segmentValues">
                <div className="presetOptionTitle">Presets</div>
                <BPMButton
                  size="sm"
                  disabled={currentSegmentList.includes(segmentOptions.TACTIC)}
                  onClick={() => addNewSegment(segmentOptions.TACTIC, index)}
                >
                  <MdAdd /> Tactic
                </BPMButton>
                <BPMButton
                  size="sm"
                  disabled={currentSegmentList.includes(segmentOptions.BRAND_VS_NONBRAND)}
                  onClick={() => addNewSegment(segmentOptions.BRAND_VS_NONBRAND, index)}
                >
                  <MdAdd /> Brand vs Nonbrand
                </BPMButton>
                <BPMButton
                  size="sm"
                  disabled={currentSegmentList.includes(segmentOptions.FUNNEL_TIER)}
                  onClick={() => addNewSegment(segmentOptions.FUNNEL_TIER, index)}
                >
                  <MdAdd /> Funnel Tier
                </BPMButton>
                <BPMButton
                  size="sm"
                  disabled={currentSegmentList.includes(segmentOptions.PROMO)}
                  onClick={() => addNewSegment(segmentOptions.PROMO, index)}
                >
                  <MdAdd /> Promo
                </BPMButton>
                <BPMButton
                  size="sm"
                  disabled={currentSegmentList.includes(segmentOptions.OBJECTIVE)}
                  onClick={() => addNewSegment(segmentOptions.OBJECTIVE, index)}
                >
                  <MdAdd /> Objective
                </BPMButton>
                <div className="customOptionTitle">Custom</div>
                <BPMButton size="sm" onClick={() => addNewSegment(segmentOptions.CUSTOM, index)}>
                  <MdAdd /> Custom Segment Type
                </BPMButton>
              </div>
            </div>
          ) : (
            <div className="segmentContainer" key={segment}>
              <div className="segmentHeader">
                <div className="segmentName">
                  <Form.Control
                    placeholder="Segment Name"
                    type="text"
                    value={newSegments[segment] || ""}
                    disabled={
                      newSegments[segment] === segmentOptions.TACTIC ||
                      newSegments[segment] === segmentOptions.BRAND_VS_NONBRAND ||
                      newSegments[segment] === segmentOptions.FUNNEL_TIER ||
                      newSegments[segment] === segmentOptions.PROMO ||
                      newSegments[segment] === segmentOptions.OBJECTIVE
                    }
                    onChange={e =>
                      handleSegmentNameOnChange({ segment, value: e.currentTarget.value })
                    }
                    onBlur={e =>
                      handleSegmentNameOnChange({
                        segment,
                        value: e.currentTarget.value,
                        shouldTrim: true,
                      })
                    }
                  />
                </div>
              </div>
              <div className="segmentValues">
                <BPMButton size="sm" onClick={() => addNewSegmentValue("", segment)}>
                  <MdAdd />
                </BPMButton>
                {Object.entries(newSegmentValues)
                  .filter(([valueId, value]) => value.segmentId === segment)
                  .map(([valueId, value]) => {
                    return (
                      <Form.Control
                        size="sm"
                        placeholder="Segment Value"
                        type="text"
                        value={value.name}
                        key={valueId}
                        onChange={e =>
                          handleSegmentValueOnChange({
                            segment,
                            valueId,
                            value: e.currentTarget.value,
                            segmentId: value.segmentId,
                          })
                        }
                        onBlur={e =>
                          handleSegmentValueOnChange({
                            segment,
                            valueId,
                            value: e.currentTarget.value,
                            segmentId: value.segmentId,
                            shouldTrim: true,
                          })
                        }
                      />
                    );
                  })}
              </div>
            </div>
          )
        )}
        <BPMButton
          className="addNewSegmentButton"
          onClick={() => {
            setSegments([...segments, "a"]);
          }}
          variant="outline-primary"
        >
          <MdAdd />
        </BPMButton>
      </div>
      {hasEdits && (
        <div className="floatingControls">
          <BPMButton onClick={discardChanges} variant="danger">
            <MdCancel />
          </BPMButton>

          <BPMButton
            disabled={Object.values(newSegments).some(value => value === "")}
            onClick={saveChanges}
            variant="success"
          >
            {saving ? <Spinner /> : <MdSave />}
          </BPMButton>
        </div>
      )}
    </div>
  ) : (
    <FullPageSpinner />
  );
};

export default CustomSegments;
